import { Loader2, PauseCircleIcon, PlayCircleIcon } from 'lucide-react';
import React from 'react';
import { match } from 'ts-pattern';

import { Box, Button } from '@eluve/components';
import { useCaptureEventWithSharedContext } from '@eluve/posthog-react';

import {
  useTranscriptionMachineActor,
  useTranscriptionMachineSelector,
} from '../machines/transcription/TranscriptionMachineProvider';

export const AppointmentTranscriptionInSessionControls: React.FC = () => {
  const appointmentId = useTranscriptionMachineSelector(
    (state) => state.context.appointmentId ?? '',
  );

  const captureEvent = useCaptureEventWithSharedContext({
    appointmentId,
  });

  const state = useTranscriptionMachineSelector((state) => {
    if (
      state.matches({
        InSession: {
          Recording: {
            MediaRecorder: 'Loading',
          },
        },
      })
    ) {
      return 'Loading';
    }

    if (
      state.matches({
        InSession: 'Recording',
      })
    ) {
      return 'Recording';
    }

    if (
      state.matches({
        InSession: 'Paused',
      })
    ) {
      return 'Paused';
    }
  });

  const { send } = useTranscriptionMachineActor();

  return match(state)
    .with('Recording', () => (
      <Box hStack>
        <Button
          onClick={() => {
            captureEvent('paused_session');
            send({ type: 'PAUSE' });
          }}
          variant="outline"
        >
          <PauseCircleIcon className="mr-2 h-5" />
          Pause
        </Button>
        <Button
          onClick={() => {
            captureEvent('ended_session');
            send({ type: 'STOP' });
          }}
          variant="dark"
        >
          End Session
        </Button>
      </Box>
    ))
    .with('Paused', () => (
      <Box hStack>
        <Button
          onClick={() => {
            captureEvent('resumed_session');
            send({ type: 'RESUME' });
          }}
          variant="outline"
        >
          <PlayCircleIcon className="mr-2 h-5" />
          Resume
        </Button>
        <Button
          onClick={() => {
            captureEvent('ended_session', {
              wasPausedWhenEnded: true,
            });
            send({ type: 'STOP' });
          }}
          variant="dark"
        >
          End Session
        </Button>
      </Box>
    ))
    .with('Loading', () => (
      <Box hStack>
        <Loader2 className="text-brand-9 mr-2 h-5 animate-spin" />
        Processing
      </Box>
    ))
    .otherwise(() => null);
};
