import { useQuery } from '@apollo/client';
import { UserIcon } from 'lucide-react';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { CopyTextButton } from '@eluve/blocks';
import {
  ColDefBuilder,
  DataTable,
  Link,
  PageSubtitle,
  makeDefaultSortableColumnDef,
  makeVisibilityToggleColumnDef,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import {
  PromptTemplateColDef,
  PromptTemplateColDefProps,
} from '../components/PromptTemplateColDef';

const getAdminTenantAppointmentsQuery = graphql(`
  query getAdminTenantAppointments($tenantId: uuid!) {
    tenantsByPk(id: $tenantId) {
      __typename
      id
      name
      appointments {
        __typename
        id
        name
        startDate
        endDate
        createdAt
        description
        patientId
        user {
          __typename
          id
          firstName
          lastName
          email
        }
        doctor_interaction {
          __typename
          appointmentId
          noteSignedAt
        }
        transcripts(orderBy: { createdAt: DESC_NULLS_LAST }, limit: 1) {
          __typename
          id
          createdAt
        }
        appointment_llm_outputs(
          orderBy: { output: { updatedAt: DESC_NULLS_LAST } }
          limit: 1
        ) {
          output {
            __typename
            id
            modelPromptTemplate {
              __typename
              id
              template {
                __typename
                name
                id
              }
            }
          }
        }
      }
    }
  }
`);

type AppointmentRow = {
  id: string;
  name: string;
  patientId: string | null;
  userId?: string;
  userName?: string;
  noteSignedAt?: string;
  startDate: string | null;
  lastTranscriptCreatedAt?: string;
} & PromptTemplateColDefProps;

const columns = new ColDefBuilder<AppointmentRow>()
  .colDef({
    id: 'id',
    accessorKey: 'id',
    header: 'Id',
    cell: ({ row }) => <CopyTextButton copyText={row.original.id} />,
  })
  .colDef({
    ...makeDefaultSortableColumnDef('name', 'Name'),
    id: 'name',
    accessorFn: (row) => row.name,
    cell: ({ row }) => {
      return (
        <Link to={`../appointments/${row.original.id}`} relative="path">
          {row.original.name ? row.original.name : row.original.id}
        </Link>
      );
    },
  })

  .defaultSortable('patientId', 'Patient ID')
  .defaultSortable('userName', 'Signed By (Name)')
  .colDef(
    makeVisibilityToggleColumnDef({
      ...makeDefaultSortableColumnDef('userId', 'Signed By (Id)'),
      id: 'userId',
      accessorFn: (row) => row.userId,
      hiddenCell: ({ row }) =>
        row.original.userId && (
          <div className="flex justify-center">
            <CopyTextButton copyText={row.original.userId}>
              <UserIcon className="h-6 w-6" />
            </CopyTextButton>
          </div>
        ),
    }),
  )
  .dateSortable('startDate', 'Start Date')
  .dateSortable('noteSignedAt', 'Signed At')
  .dateSortable('lastTranscriptCreatedAt', 'Last Transcript')
  .colDef(PromptTemplateColDef())
  .build();

export const AdminAppointmentsDash: React.FC = () => {
  const { tenantId } = useParams() as { tenantId: string };
  const { data } = useQuery(getAdminTenantAppointmentsQuery, {
    variables: {
      tenantId: tenantId,
    },
  });

  const rows = useMemo(
    () =>
      (data?.tenantsByPk?.appointments ?? []).map<AppointmentRow>((appt) => {
        const lastTranscriptCreatedAt =
          appt.transcripts[0]?.createdAt ?? undefined;

        const output =
          appt.appointment_llm_outputs[0]?.output.modelPromptTemplate;

        return {
          id: appt.id,
          name: appt.name,
          patientId: appt.patientId,
          startDate: appt.startDate,
          noteSignedAt: appt.doctor_interaction?.noteSignedAt ?? undefined,
          userId: appt.user?.id,
          userName: [appt.user?.firstName, appt.user?.lastName]
            .filter(Boolean)
            .join(' '),
          lastTranscriptCreatedAt,
          promptTemplate: output?.template ?? null,
        };
      }),
    [data],
  );

  return (
    <div>
      <PageSubtitle> Appointments</PageSubtitle>
      <div>
        <DataTable data={rows} columns={columns} enableGlobalSearch />
      </div>
    </div>
  );
};
