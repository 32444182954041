import { CircleAlertIcon } from 'lucide-react';
import { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  P,
} from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';
import {
  useTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';

import { useTranscriptionMachineSelector } from '../TranscriptionMachineProvider';

export const RecordingFailedDialog = () => {
  const logger = useNamedLogger('MicrophoneFailedDialog');
  const recordingFailed = useTranscriptionMachineSelector(
    (s) => s.context.recordingFailed,
  );
  const appointmentId = useTranscriptionMachineSelector(
    (s) => s.context.sessionAppointmentId,
  );
  const userId = useUserIdFromSession();
  const tenantId = useTenantIdFromParams();
  const hasFailed = useTranscriptionMachineSelector(
    (s) => s.can({ type: 'RESUME' }) && recordingFailed,
  );

  const [open, setOpen] = useState(hasFailed);

  useEffect(() => {
    setOpen(hasFailed);
  }, [hasFailed]);

  if (!open) {
    return null;
  }

  logger.warn('RecordingFailedDialog shown', {
    recordingFailed,
    appointmentId,
    userId,
    tenantId,
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent overlay={false}>
        <DialogHeader>
          <DialogTitle className="text-destructive">
            <CircleAlertIcon className="mr-4 inline-flex size-6" />
            {`Session is no longer in progress.`}
          </DialogTitle>
          <DialogDescription>
            {'Refresh the page to resume the session.'}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button onClick={() => window.location.reload()}>
            <div className="flex flex-row">
              <P>Refresh Page</P>
            </div>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
