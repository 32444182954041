import { getOriginPrivateDirectory } from 'native-file-system-adapter';
import React, { useEffect } from 'react';

import { useNamedLogger } from '@eluve/logger';

import { SWAP_FILE_EXT, readAppointmentFilesFromOpfs } from './opfs';
import {
  useUserFileSystemStore,
  useUserFileSystemStoreApi,
} from './user-file-system.store';

export interface UserFileSystemInitializerProps {
  children: React.ReactNode;
}

export const UserFileSystemInitializer: React.FC<
  UserFileSystemInitializerProps
> = ({ children }) => {
  const {
    userId,
    tenantId,
    userFileSystem: storage,
  } = useUserFileSystemStore((state) => state);
  const storeApi = useUserFileSystemStoreApi();

  const logger = useNamedLogger('UserFileSystemInitializer');

  useEffect(
    function attemptInitLocalStorage() {
      const init = async () => {
        try {
          // This is ponyflll for Safari. If createWritable is not available, it will add it
          await getOriginPrivateDirectory();

          const opfsRoot = await navigator.storage.getDirectory();
          const userDir = await opfsRoot.getDirectoryHandle(userId, {
            create: true,
          });

          const tenantDir = await userDir.getDirectoryHandle(tenantId, {
            create: true,
          });

          const appointmentsDir = await tenantDir.getDirectoryHandle(
            'appointments',
            {
              create: true,
            },
          );

          storeApi.setState({
            userFileSystem: {
              type: 'available',
              topLevelUserDir: userDir,
              tenantDir,
              appointmentsDir,
            },
          });
        } catch (e) {
          logger.error('Failed to initialize user file system', { error: e });
          storeApi.setState({
            userFileSystem: {
              type: 'unavailable',
            },
          });
        }
      };

      init();
    },
    [storage.type, userId, storeApi, tenantId, logger],
  );

  useEffect(
    function reportLocalFileStats() {
      let isRunning = false;
      const interval = setInterval(
        async () => {
          try {
            if (isRunning) {
              return;
            }
            isRunning = true;

            if (storage.type === 'available') {
              const filePromises: Promise<{
                localFileCount: number;
                localSwapFileCount: number;
              }>[] = [];

              const emptyDirPromises: Promise<void>[] = [];
              const scanStart = new Date();

              for await (const [id, handle] of storage.appointmentsDir) {
                if (handle.kind === 'directory') {
                  const promise = async () => {
                    let localFileCount = 0;
                    let localSwapFileCount = 0;
                    const appointmentFilesResult =
                      await readAppointmentFilesFromOpfs(storage, id);

                    if (
                      appointmentFilesResult.type === 'directoryExists' &&
                      appointmentFilesResult.files.length
                    ) {
                      for (const file of appointmentFilesResult.files) {
                        if (file.name.endsWith(SWAP_FILE_EXT)) {
                          localSwapFileCount++;
                        } else {
                          localFileCount++;
                        }
                      }
                    } else {
                      // Remove empty directories
                      emptyDirPromises.push(
                        storage.appointmentsDir.removeEntry(id),
                      );
                    }

                    return {
                      localFileCount,
                      localSwapFileCount,
                    };
                  };

                  filePromises.push(promise());
                }
              }

              let localFileCount = 0;
              let localSwapFileCount = 0;
              const results = await Promise.all(filePromises);
              for (const result of results) {
                localFileCount += result.localFileCount;
                localSwapFileCount += result.localSwapFileCount;
              }

              const scanEnd = new Date();

              logger.info('Local file stats', {
                localFileCount,
                localSwapFileCount,
                emptyDirsToRemove: emptyDirPromises.length,
                scanDuration: scanEnd.getTime() - scanStart.getTime(),
              });
            }
          } catch (e) {
            logger.error(
              'Error while reporting local file stats',
              e as Record<string, unknown>,
            );
          }

          isRunning = false;
        },
        // Report file stats every 10 minutes
        // Offset by 3333ms to reduce collisions with the interval running
        // in PeriodicFileSyncProvider.
        // TODO(jesse)[ELU-2124] We won't need a weird offset once we consolidate
        1000 * 60 * 10 + 3333,
      );

      return () => {
        clearInterval(interval);
      };
    },
    [storage, logger],
  );

  if (storage.type === 'pending') {
    return null;
  }

  return children;
};
