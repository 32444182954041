import { ApolloProvider } from '@apollo/client';
import { ErrorBoundary } from '@sentry/react';
import { Suspense } from 'react';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';
import 'supertokens-auth-react';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';

import {
  BrowserSessionProvider,
  apolloClientFactory,
  defaultCache,
  sessionAccessTokenProvider,
} from '@eluve/apollo-client';
import { PatientDetailsPage, PatientsListPage } from '@eluve/feature-patients';
import {
  AppointmentDetailsOutput,
  AppointmentDetailsPage,
  AppointmentPdfPage,
} from '@eluve/frontend-feature-appointment';
import { EHRSyncStoreProvider } from '@eluve/frontend-feature-ehr-sync';
import {
  ArtifactDetailsPage,
  ArtifactListPage,
  CreateArtifactPage,
  CreateDatasetPage,
  CreateWorkflowPage,
  DatasetDetailsPage,
  DatasetsListPage,
  FactVerificationIndexPage,
  FactVerificationModelPromptTemplatePage,
  FactVerificationWorkflowDetailsPage,
} from '@eluve/frontend-feature-eval';
import {
  ClonePromptPage,
  ModelPromptTemplateDetailsPage,
  ModelsListPage,
  PromptTemplateDetailsLayout,
  PromptTemplateDetailsPromptTextPage,
  PromptTemplateModelArgsListPage,
  PromptTemplateUsersPage,
} from '@eluve/frontend-feature-prompt-templates';
import { FeatureFlaggedPage } from '@eluve/smart-blocks';
import { AppointmentFilesList } from '@eluve/user-local-files';
import { AppointmentTasksMachineProvider } from '@eluve/user-local-files';

import { AnalyticsProvider } from './AnalyticsProvider';
import { appConfig } from './config';
import { ErrorPage } from './routes/ErrorPage';
import { LoginCallbackPage } from './routes/LoginCallbackPage';
import { LoginPage } from './routes/LoginPage';
import { NotFoundPage } from './routes/NotFoundPage';
import { SsoPage } from './routes/SsoPage';
import { WaitListPage } from './routes/WaitlistPage';
import { AppIndexPage } from './routes/app/AppIndexPage';
import { AppLayout } from './routes/app/AppLayout';
import { SingularEntityRedirect } from './routes/app/SingularEntityRedirect';
import { UserContextProvider } from './routes/app/UserContextProvider';
import { VersionChecker } from './routes/app/VersionChecker';
import { AdminAuditTrail } from './routes/app/admin/AdminAuditTrail';
import { AdminLayout } from './routes/app/admin/AdminLayout';
import { GlobalFeatureFlagsPage } from './routes/app/admin/global-features-flags/GlobalFeatureFlagsPage';
import { AdminTenant } from './routes/app/admin/tenant/$tenantId/AdminTenant';
import { AdminTenantFeatureFlagsPage } from './routes/app/admin/tenant/$tenantId/AdminTenantFeatureFlagsPage';
import { AdminTenantLayout } from './routes/app/admin/tenant/$tenantId/AdminTenantLayout';
import { AdminTenantLlmOutputsPage } from './routes/app/admin/tenant/$tenantId/AdminTenantLlmOutputsPage';
import { AdminTenantPromptTemplatesPage } from './routes/app/admin/tenant/$tenantId/AdminTenantPromptTemplatesPage';
import { AssetDeletionDash } from './routes/app/admin/tenant/$tenantId/asset-deletion/AssetDeletionDash';
import { TenantDash } from './routes/app/admin/tenant/$tenantId/dash/TenantDash';
import { AdminAppointment } from './routes/app/admin/tenant/$tenantId/dash/appointments/$appointmentId/AdminAppointment';
import { AdminAppointmentOutlet } from './routes/app/admin/tenant/$tenantId/dash/appointments/$appointmentId/AdminAppointmentOutlet';
import { AdminAppointmentArtifacts } from './routes/app/admin/tenant/$tenantId/dash/appointments/$appointmentId/components/AdminAppointmentArtifacts';
import { AdminAppointmentFiles } from './routes/app/admin/tenant/$tenantId/dash/appointments/$appointmentId/components/AdminAppointmentFiles';
import { AdminAppointmentSourceArtifacts } from './routes/app/admin/tenant/$tenantId/dash/appointments/$appointmentId/components/AdminAppointmentSourceArtifacts';
import { AdminTranscriptsPage } from './routes/app/admin/tenant/$tenantId/dash/appointments/$appointmentId/components/AdminTranscriptsPage';
import { AdminAppointmentsDash } from './routes/app/admin/tenant/$tenantId/dash/appointments/AdminAppointmentsDash';
import { AdminTenantUsers } from './routes/app/admin/tenant/$tenantId/users/AdminTenantUsers';
import { AdminTenants } from './routes/app/admin/tenant/AdminTenants';
import { EvalGuard } from './routes/app/eval/EvalGuard';
import { EvalLayout } from './routes/app/eval/EvalLayout';
import { SourceArtifactPendingReviewListPage } from './routes/app/eval/SourceArtifactPendingReviewListPage';
import { CreatePromptPage } from './routes/app/prompts/CreatePromptPage';
import { PromptTemplatesListPage } from './routes/app/prompts/PromptTemplatesListPage';
import { PromptsGuard } from './routes/app/prompts/PromptsGuard';
import { SettingsPage } from './routes/app/settings/SettingsPage';
import { HomePage } from './routes/app/tenant/$tenantId/HomePage';
import { PeriodicFileSyncProviderBridge } from './routes/app/tenant/$tenantId/PeriodicFileSyncProviderBridge';
import { TenantAdminGuard } from './routes/app/tenant/$tenantId/TenantAdminGuard';
import { TenantGuard } from './routes/app/tenant/$tenantId/TenantGuard';
import { TenantLayout } from './routes/app/tenant/$tenantId/TenantLayout';
import { TenantProvider } from './routes/app/tenant/$tenantId/TenantProvider';
import { TenantSettingsMainLayout } from './routes/app/tenant/$tenantId/TenantSettingsMainLayout';
import { LayersPage } from './routes/app/tenant/$tenantId/admin/LayersPage';
import { LocationPage } from './routes/app/tenant/$tenantId/admin/LocationPage';
import { LocationsPage } from './routes/app/tenant/$tenantId/admin/LocationsPage';
import { SessionTypesPage } from './routes/app/tenant/$tenantId/admin/SessionTypesPage';
import { AppointmentPage } from './routes/app/tenant/$tenantId/appointments/Appointment.Page';
import { AppointmentsListPage } from './routes/app/tenant/$tenantId/appointments/AppointmentsListPage';
import { AppointmentsMainLayout } from './routes/app/tenant/$tenantId/appointments/AppointmentsMainLayout';
import { SummaryComparisonPage } from './routes/app/tenant/$tenantId/appointments/SummaryComparison.Page';
import { TranscriptionMachineProvider } from './routes/app/tenant/$tenantId/appointments/machines/transcription/TranscriptionMachineProvider';
import { TranscriptionWidget } from './routes/app/tenant/$tenantId/appointments/machines/transcription/components/TranscriptionWidget';
import { PendingApprovalPage } from './routes/app/tenant/PendingApprovalPage';
import { TenantsPage } from './routes/app/tenant/TenantsPage';
import { TenantsHeader } from './routes/app/tenant/components/TenantsHeader';

const adminApolloClient = apolloClientFactory({
  uri: appConfig.VITE_APOLLO_CLIENT_URI,
  desiredRole: 'eluve-admin',
  cacheInstance: defaultCache,
  accessTokenProvider: sessionAccessTokenProvider(new BrowserSessionProvider()),
});

const rootApolloClient = apolloClientFactory({
  uri: appConfig.VITE_APOLLO_CLIENT_URI,
  cacheInstance: defaultCache,
  accessTokenProvider: sessionAccessTokenProvider(new BrowserSessionProvider()),
});

const evalApolloClient = apolloClientFactory({
  uri: appConfig.VITE_APOLLO_CLIENT_URI,
  desiredRole: 'eval-user',
  cacheInstance: defaultCache,
  accessTokenProvider: sessionAccessTokenProvider(new BrowserSessionProvider()),
});

export const reactRouter = createBrowserRouter([
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/login/callback/*',
    element: <LoginCallbackPage />,
  },
  {
    path: '/sso',
    element: <SsoPage />,
  },
  {
    path: '/waitlist',
    element: <WaitListPage />,
  },
  {
    path: '/',
    element: (
      <ErrorBoundary
        showDialog={true}
        fallback={({ resetError }) => <ErrorPage reset={resetError} />}
      >
        <SessionAuth>
          <AnalyticsProvider>
            <UserContextProvider>
              <VersionChecker intervalMinutes={5}>
                <ApolloProvider client={rootApolloClient}>
                  <AppLayout />
                </ApolloProvider>
              </VersionChecker>
            </UserContextProvider>
          </AnalyticsProvider>
        </SessionAuth>
      </ErrorBoundary>
    ),
    children: [
      {
        index: true,
        element: <AppIndexPage />,
      },
      {
        path: 'tenant/*',
        element: <SingularEntityRedirect entityName="tenant" />,
      },
      {
        path: 'tenants',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <TenantsPage />,
          },
          {
            path: 'pending-approval/:tenantId',
            element: <PendingApprovalPage />,
          },
          {
            path: ':tenantId',
            element: (
              <TenantGuard>
                <Suspense
                  fallback={
                    <div className="grid h-screen place-content-center">
                      Loading...
                    </div>
                  }
                >
                  <EHRSyncStoreProvider
                    initializer={(set) => ({
                      lastSuccessImportCompletedAt: undefined,
                      isDataImporting: undefined,
                      setIsDataImporting: (isLoading) =>
                        set({ isDataImporting: isLoading }),
                    })}
                  >
                    <TenantProvider>
                      <AppointmentTasksMachineProvider>
                        <TranscriptionMachineProvider>
                          <PeriodicFileSyncProviderBridge />
                          <TenantLayout />
                          <TranscriptionWidget />
                        </TranscriptionMachineProvider>
                      </AppointmentTasksMachineProvider>
                    </TenantProvider>
                  </EHRSyncStoreProvider>
                </Suspense>
              </TenantGuard>
            ),
            children: [
              {
                index: true,
                element: <Navigate to="home" replace />,
              },
              {
                path: 'home',
                element: <HomePage />,
              },
              {
                path: 'admin',
                element: (
                  <TenantAdminGuard>
                    <Outlet />
                  </TenantAdminGuard>
                ),
                children: [
                  {
                    index: true,
                    element: <Navigate to="layers" replace />,
                  },
                  {
                    element: <TenantSettingsMainLayout />,
                    children: [
                      {
                        path: 'layers',
                        element: (
                          <FeatureFlaggedPage flag="LOCATIONS">
                            <LayersPage />
                          </FeatureFlaggedPage>
                        ),
                      },
                      {
                        path: 'locations',
                        element: (
                          <FeatureFlaggedPage flag="LOCATIONS">
                            <Outlet />
                          </FeatureFlaggedPage>
                        ),
                        children: [
                          {
                            index: true,
                            element: <LocationsPage />,
                          },
                          {
                            path: ':locationId',
                            element: <LocationPage />,
                          },
                        ],
                      },
                      {
                        path: 'session-types',
                        element: (
                          <FeatureFlaggedPage flag="SESSION_TYPES">
                            <SessionTypesPage />
                          </FeatureFlaggedPage>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'files',
                element: (
                  <FeatureFlaggedPage flag="LOCAL_FILES_MANAGEMENT">
                    <AppointmentFilesList />
                  </FeatureFlaggedPage>
                ),
              },
              {
                path: 'patient/*',
                element: <SingularEntityRedirect entityName="patient" />,
              },
              {
                path: 'appointments',
                element: (
                  <FeatureFlaggedPage flag="APPOINTMENTS_VIEW">
                    <AppointmentsListPage />
                  </FeatureFlaggedPage>
                ),
              },
              {
                path: 'patients',
                element: (
                  <FeatureFlaggedPage flag="PATIENTS_VIEW">
                    <PatientsListPage />
                  </FeatureFlaggedPage>
                ),
              },
              {
                path: 'patients/:patientId',
                element: (
                  <FeatureFlaggedPage flag="PATIENTS_VIEW">
                    <PatientDetailsPage />
                  </FeatureFlaggedPage>
                ),
              },
              {
                path: 'appointment/*',
                element: <SingularEntityRedirect entityName="appointment" />,
              },
              {
                path: 'appointments/:appointmentId',
                element: <AppointmentsMainLayout />,
                children: [
                  {
                    index: true,
                    element: <AppointmentPage />,
                  },
                  {
                    path: 'comparison',
                    element: <SummaryComparisonPage />,
                  },
                  {
                    path: 'pdf',
                    element: (
                      <FeatureFlaggedPage flag="PDF_OUTPUT">
                        <AppointmentPdfPage />
                      </FeatureFlaggedPage>
                    ),
                  },
                  {
                    path: 'details',
                    element: (
                      <FeatureFlaggedPage flag="LLM_OUTPUT_DETAILS">
                        <AppointmentDetailsPage />
                      </FeatureFlaggedPage>
                    ),
                    children: [
                      {
                        index: true,
                        element: (
                          <div className="text-gray-10">
                            Select Output types
                          </div>
                        ),
                      },
                      {
                        path: ':outputType',
                        element: <AppointmentDetailsOutput />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'prompts',
        element: (
          <Suspense fallback={'Loading...'}>
            <PromptsGuard>
              <div>
                <TenantsHeader />
                <CreatePromptPage />
              </div>
            </PromptsGuard>
          </Suspense>
        ),
      },
      {
        path: 'settings',
        element: (
          <Suspense fallback={'Loading...'}>
            <EHRSyncStoreProvider
              initializer={(set) => ({
                lastSuccessImportCompletedAt: undefined,
                isDataImporting: undefined,
                setIsDataImporting: (isLoading) =>
                  set({ isDataImporting: isLoading }),
              })}
            >
              <SettingsPage />
            </EHRSyncStoreProvider>
          </Suspense>
        ),
      },
      {
        path: 'admin',
        element: <AdminLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="tenants" />,
          },
          {
            path: 'tenants',
            element: (
              <ApolloProvider client={adminApolloClient}>
                <AdminTenants />
              </ApolloProvider>
            ),
          },
          {
            path: 'audit-trail',
            element: (
              <ApolloProvider client={adminApolloClient}>
                <AdminAuditTrail />
              </ApolloProvider>
            ),
          },
          {
            path: 'fact-verification',
            element: (
              <ApolloProvider client={adminApolloClient}>
                <Outlet />
              </ApolloProvider>
            ),
            children: [
              {
                index: true,
                element: <FactVerificationIndexPage />,
              },
              {
                path: 'workflow/create',
                element: <CreateWorkflowPage />,
              },
              {
                path: ':workflowId',
                element: <FactVerificationWorkflowDetailsPage />,
              },
              {
                path: ':workflowId/:modelPromptTemplateId',
                element: <FactVerificationModelPromptTemplatePage />,
              },
              {
                path: 'artifacts',
                element: <ArtifactListPage />,
              },
              {
                path: 'artifacts/:artifactId',
                element: <ArtifactDetailsPage />,
              },
              {
                path: 'artifacts/create',
                element: <CreateArtifactPage />,
              },
              {
                path: 'datasets',
                element: <DatasetsListPage />,
              },
              {
                path: 'datasets/:datasetId',
                element: <DatasetDetailsPage />,
              },
              {
                path: 'datasets/create',
                element: <CreateDatasetPage />,
              },
            ],
          },
          {
            path: 'models',
            element: (
              <ApolloProvider client={adminApolloClient}>
                <ModelsListPage />
              </ApolloProvider>
            ),
          },
          {
            path: 'prompts',
            element: (
              <ApolloProvider client={adminApolloClient}>
                <Suspense fallback={'Loading...'}>
                  <Outlet />
                </Suspense>
              </ApolloProvider>
            ),
            children: [
              {
                index: true,
                element: <PromptTemplatesListPage />,
              },
              {
                path: 'create',
                element: <CreatePromptPage />,
              },
              {
                path: 'clone/:promptTemplateId',
                element: <ClonePromptPage />,
              },
              {
                path: ':promptTemplateId',
                element: <PromptTemplateDetailsLayout />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="prompt" replace />,
                  },
                  {
                    path: 'prompt',
                    element: <PromptTemplateDetailsPromptTextPage />,
                  },
                  {
                    path: 'model-args',
                    element: <PromptTemplateModelArgsListPage />,
                  },
                  {
                    path: 'users',
                    element: <PromptTemplateUsersPage />,
                  },
                ],
              },
              {
                path: ':promptTemplateId/:modelPromptTemplateId',
                element: <ModelPromptTemplateDetailsPage />,
              },
            ],
          },
          {
            path: 'tenant/*',
            element: <SingularEntityRedirect entityName="tenant" />,
          },
          {
            path: 'tenants/:tenantId',
            element: (
              <TenantGuard>
                <AdminTenantLayout />
              </TenantGuard>
            ),
            children: [
              {
                index: true,
                element: <Navigate to="manage" replace />,
              },
              {
                path: 'manage',
                element: <AdminTenant />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="feature-flags" replace />,
                  },
                  {
                    path: 'feature-flags',
                    element: <AdminTenantFeatureFlagsPage />,
                  },
                  {
                    path: 'llm-outputs',
                    element: <AdminTenantLlmOutputsPage />,
                  },
                  {
                    path: 'prompt-templates',
                    element: <AdminTenantPromptTemplatesPage />,
                  },
                ],
              },
              {
                path: 'dash',
                element: <TenantDash />,
              },
              {
                path: 'asset-deletion',
                element: <AssetDeletionDash />,
              },
              {
                path: 'appointment/*',
                element: <SingularEntityRedirect entityName="appointment" />,
              },
              {
                path: 'appointments',
                element: <AdminAppointmentsDash />,
              },
              {
                path: 'appointments/:appointmentId',
                element: <AdminAppointmentOutlet />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="details" replace />,
                  },
                  {
                    path: 'details',
                    element: <AdminAppointment />,
                  },
                  {
                    path: 'artifacts',
                    element: <AdminAppointmentArtifacts />,
                  },
                  {
                    path: 'files',
                    element: <AdminAppointmentFiles />,
                  },
                  {
                    path: 'transcript-comparison',
                    element: <AdminTranscriptsPage />,
                  },
                  {
                    path: 'source-artifacts',
                    element: (
                      <ApolloProvider client={adminApolloClient}>
                        <AdminAppointmentSourceArtifacts />
                      </ApolloProvider>
                    ),
                  },
                ],
              },
              {
                path: 'users',
                element: <AdminTenantUsers />,
              },
            ],
          },

          {
            path: 'global-feature-flags',
            element: (
              <ApolloProvider client={adminApolloClient}>
                <GlobalFeatureFlagsPage />
              </ApolloProvider>
            ),
          },
        ],
      },
      {
        path: 'eval',
        element: (
          <Suspense fallback={'Loading...'}>
            <EvalGuard>
              <ApolloProvider client={evalApolloClient}>
                <EvalLayout />
              </ApolloProvider>
            </EvalGuard>
          </Suspense>
        ),
        children: [
          {
            path: 'source-artifact-review',
            element: <SourceArtifactPendingReviewListPage />,
          },
          {
            path: 'source-artifact-review/:artifactId',
            element: <ArtifactDetailsPage />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]);
