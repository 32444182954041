import { useMutation } from '@apollo/client';
import { format } from 'date-fns';
import { toast } from 'sonner';

import { useCompleteFragment } from '@eluve/apollo-client';
import { ContentEditable } from '@eluve/blocks';
import { Box } from '@eluve/components';
import { useAppointmentContext } from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';

const appointmentNameAndDateFragment = graphql(`
  fragment AppointmentNameFragment on Appointments {
    name
    startDate
  }
`);

const updateAppointmentNameMutation = graphql(`
  mutation UpdateAppointmentName($tenantId: uuid!, $id: uuid!, $name: String!) {
    updateAppointmentsByPk(
      pkColumns: { tenantId: $tenantId, id: $id }
      _set: { name: $name }
    ) {
      id
      name
    }
  }
`);

interface AppointmentNameProps {
  readonly?: boolean;
}

export const AppointmentName: React.FC<AppointmentNameProps> = ({
  readonly,
}) => {
  const { tenantId, appointmentId } = useAppointmentContext();

  const { name, startDate } = useCompleteFragment({
    fragment: appointmentNameAndDateFragment,
    key: {
      id: appointmentId,
    },
  });

  const [updateAppointmentName] = useMutation(updateAppointmentNameMutation, {
    optimisticResponse: (variables) => ({
      updateAppointmentsByPk: {
        id: variables.id,
        name: variables.name,
        __typename: 'Appointments',
      },
    }),
    onError: () => {
      toast.error('Failed to update appointment name');
    },
    onCompleted: () => {
      toast.success('Appointment name updated');
    },
  });

  const handleNameUpdate = (name: string) => {
    updateAppointmentName({
      variables: {
        tenantId,
        id: appointmentId,
        name,
      },
    });
  };

  const NameHeading = (
    <h2 className="text-lg font-bold lg:text-[28px]">{name}</h2>
  );

  return (
    <Box vStack className="w-max gap-1">
      <h6 className="text-gray-10">
        {startDate && format(new Date(startDate), 'MMMM d, yyyy')}
      </h6>
      {readonly ? (
        NameHeading
      ) : (
        <ContentEditable
          editButtonLabel="Update Appointment Name"
          onContentChange={handleNameUpdate}
        >
          {NameHeading}
        </ContentEditable>
      )}
    </Box>
  );
};
