import { Box, P } from '@eluve/components';
import {
  useAppointmentActivity,
  useAppointmentId,
  useIsAppointmentReadonly,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import {
  CustomInstructions,
  RegenerateSummaryButton,
  SummaryFeedbackModal,
  SummarySectionMarkdown,
  UserFeatureFlaggedComponent,
} from '@eluve/smart-blocks';
import { AppointmentSummaryKeys } from '@eluve/utils';

import { SummarizationErrorCard } from '../SummarizationErrorCard';
import { useEditSummary } from '../useEditSummary';

import { usePendingUnsavedChanges } from './AppointmentLastSaved';
import { ClientRecapMailToAction } from './ClientRecapMailToAction';

const SummaryInprogress = () => (
  <Box
    vStack
    fullWidth
    className="max-w-[500px] items-center gap-8 self-center pt-9 text-center"
  >
    <h4 className="text-gray-12 text-2xl">Generating Notes...</h4>
    <p className="text-gray-11 text-lg">
      You can leave this page and start a new session! We&apos;ll let you know
      when your note is ready.
    </p>
  </Box>
);

const SummaryResult = () => {
  const currentSummary = useSummary();
  const summary = currentSummary?.summary ?? {};
  const summaryDetected = Object.values(summary).some((s) => s?.length);
  const editSummary = useEditSummary();
  const appointmentId = useAppointmentId();

  const isReadonly = useIsAppointmentReadonly();

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full justify-start gap-4 pb-7">
        <RegenerateSummaryButton />
        <UserFeatureFlaggedComponent flag="NOTE_REGENERATION_WITH_CUSTOM_INSTRUCTIONS">
          <CustomInstructions />
        </UserFeatureFlaggedComponent>
      </div>
      <SummarizationErrorCard />

      {summaryDetected &&
        Object.entries(summary).map(([summaryKey, summaryValue]) => {
          return (
            <SummarySectionMarkdown
              appointmentId={appointmentId}
              key={summaryKey + currentSummary?.llmOutputId}
              summaryKey={summaryKey as AppointmentSummaryKeys}
              sectionTitle={summaryKey}
              content={summaryValue ?? ''}
              disabled={isReadonly}
              handleContentChange={(key, content) => {
                usePendingUnsavedChanges.setState(true);
                editSummary(key, content);
              }}
              additionalActions={
                summaryKey === 'CLIENT_RECAP'
                  ? [ClientRecapMailToAction]
                  : undefined
              }
            />
          );
        })}

      {summaryDetected && (
        <div className="flex w-full flex-row items-center justify-end gap-2">
          <P className="text-gray-10 font-normal">
            Was this medical summary helpful?
          </P>
          <SummaryFeedbackModal
            appointmentId={appointmentId}
            llmOutputId={currentSummary?.llmOutputId}
          />
        </div>
      )}
    </div>
  );
};

export const AppointmentSummaryResult = () => {
  const { isSummarizationInProgress } = useAppointmentActivity();

  if (isSummarizationInProgress) return <SummaryInprogress />;

  return <SummaryResult />;
};
