import { cn } from './cn';
import { FCC } from './types';
import { H1, H3 } from './typography';

export const PageTitle: FCC<{
  className?: string;
  isFullWidth?: boolean;
  subtitle?: React.ReactNode;
}> = ({ children, subtitle, className, isFullWidth = false }) => {
  return (
    <div className={cn('my-2 py-2', { 'w-full': isFullWidth })}>
      <H1 className={cn('text-gray-11 font-light', className)}>
        <div>{children}</div>
      </H1>
      {subtitle && <H3 className="text-gray-10 font-light">{subtitle}</H3>}
    </div>
  );
};
