import { useQuery } from '@apollo/client';
import { add } from 'date-fns';
import { CalendarSearchIcon, PanelLeftOpenIcon } from 'lucide-react';
import React, { useEffect, useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';

import {
  AgendaTimeline,
  AgendaTimelineGroup,
  DateNavigator,
  groupOverlapItems,
} from '@eluve/blocks';
import {
  Box,
  Button,
  Calendar,
  DetailsSidebar,
  DetailsSidebarTrigger,
  H1,
  ReskinContent,
  ReskinHeader,
  ReskinMain,
  SidebarTrigger,
} from '@eluve/components';
import { convertUrlStringDate, urlSafeDate } from '@eluve/date-utils';
import { useAppointmentCounts } from '@eluve/frontend-appointment-hooks';
import { useEHRSyncStore } from '@eluve/frontend-feature-ehr-sync';
import { graphql } from '@eluve/graphql.tada';
import { useTenantIdFromParams } from '@eluve/session-helpers';
import {
  useIsTenantAdminFromSession,
  useUserIdFromSession,
} from '@eluve/session-helpers';
import { FeatureFlaggedComponent } from '@eluve/smart-blocks';

import {
  AppointmentAgendaTimelineItem,
  appointmentTimelineInfoFragment,
} from './components/AppointmentAgendaTimelineItem';
import { AppointmentsLoader } from './components/AppointmentsLoader';
import { EhrDataImporter } from './components/EhrDataImporter';
import { EhrOnboarding } from './components/EhrOnboarding';
import { EmptyHomePageState } from './components/EmptyHomePageState';
import { NewAppointmentButton } from './components/NewAppointmentButton';
import { NoAppointmentPlaceholder } from './components/NoAppointmentPlaceholder';
import { ShowAllAppointmentsToggle } from './components/ShowAllAppointmentsToggle';
import { useShowAllAppointmentsToggle } from './hooks/useShowAllAppointmentsToggle';
import { useImportDataFromEhr } from './useImportDataFromEhr';

const getAppointmentRangeSummary = graphql(
  `
    query getAppointmentRangeSummary($min: timestamptz!, $max: timestamptz!) {
      appointments(
        where: {
          _and: [
            { startDate: { _gte: $min } }
            { startDate: { _lt: $max } }
            { status: { _neq: CANCELLED } }
          ]
        }
      ) {
        ...AppointmentInfo
      }
    }
  `,
  [appointmentTimelineInfoFragment],
);

export const HomePage: React.FC = () => {
  const isTenantAdmin = useIsTenantAdminFromSession();
  const lastSuccessfulEhrSyncAt = useEHRSyncStore(
    (s) => s.lastSuccessfulEhrSyncAt,
  );
  const userId = useUserIdFromSession();
  const [showAllAppointments, setShowAllAppointments] =
    useShowAllAppointmentsToggle();
  const tenantId = useTenantIdFromParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const dateSearchParam = searchParams.get('date') ?? urlSafeDate();

  const date = convertUrlStringDate(dateSearchParam);

  const setDate = (date: Date | undefined) => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set('date', urlSafeDate(date ?? new Date()));
    setSearchParams(urlSearchParams);
  };

  const {
    totalAppointmentsCount,
    upcomingAppointmentsCount,
    refetch: refetchAppointmentCountData,
    loading: loadingAppointmentCountData,
  } = useAppointmentCounts({ days: 7, fromDate: dateSearchParam });

  const range = [date.toISOString(), add(date, { days: 1 }).toISOString()];

  const { data, refetch, error, loading } = useQuery(
    getAppointmentRangeSummary,
    {
      skip: !dateSearchParam,
      variables: {
        min: range[0]!,
        max: range[1]!,
      },
    },
  );

  const { isSupportedBrowser } = useImportDataFromEhr(tenantId!);
  const displayEmptyHomePageState =
    isSupportedBrowser &&
    !showAllAppointments &&
    (!totalAppointmentsCount || !upcomingAppointmentsCount);

  const appointments = useMemo(() => {
    const appointments = data?.appointments ?? [];
    if (showAllAppointments) {
      return appointments;
    }
    return appointments.filter((a) => a.userId === userId);
  }, [data?.appointments, showAllAppointments, userId]);

  const appointmentsGrouped = useMemo(
    () =>
      groupOverlapItems(appointments, (a) => [
        new Date(a.startDate),
        new Date(a.endDate ?? add(new Date(a.startDate), { hours: 1 })),
      ]),
    [appointments],
  );

  useEffect(
    function displayToastOnError() {
      if (error) {
        toast.error('Failed to retrieve appointments');
      }
    },
    [error],
  );

  useEffect(
    function refetchOnEhrSync() {
      if (lastSuccessfulEhrSyncAt) {
        refetch();
        refetchAppointmentCountData();
      }
    },
    [lastSuccessfulEhrSyncAt, refetch],
  );

  return (
    <ReskinMain>
      <ReskinHeader className="items-center py-3 md:px-6 lg:py-3">
        <SidebarTrigger asChild>
          <Button size="icon" variant="ghost">
            <PanelLeftOpenIcon />
          </Button>
        </SidebarTrigger>
        <Box spaceBetween className="w-full items-center gap-2 ">
          <H1 className="text-content1 xs:text-2xl pl-2 text-base font-medium">
            Overview
          </H1>
          <NewAppointmentButton />
        </Box>
        <DetailsSidebarTrigger asChild breakpointWidth={1260}>
          <Button size="icon" variant="ghost">
            <CalendarSearchIcon />
          </Button>
        </DetailsSidebarTrigger>
      </ReskinHeader>
      <ReskinContent variant="fullWidth" className="p-0">
        <div className="flex h-full w-full justify-center justify-items-center">
          <Box
            vStack
            className="h-content border-gray-5 xs:m-4 xs:rounded-xl xs:border xs:p-4 xs:pt-0 m-1 w-full gap-3 bg-white"
          >
            <Box
              vStack
              className="w-full flex-col gap-4 border-b p-3 sm:flex-row sm:items-center"
            >
              <ShowAllAppointmentsToggle />
              <DateNavigator
                onDateChanged={(newDate) => setDate(newDate)}
                startingDate={date}
                key={date.toDateString()}
              />
            </Box>
            <Box
              zStack
              className={`[data-empty="true"]:overflow-hidden h-full w-full overflow-y-auto`}
              data-empty={displayEmptyHomePageState}
            >
              <AgendaTimeline day={date} showGrid={!displayEmptyHomePageState}>
                {appointmentsGrouped.map((appointments, index) => {
                  if (appointments.length === 1) {
                    // We know this is safe because we're checking the length
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const appointment = appointments[0]!;
                    return (
                      <AppointmentAgendaTimelineItem
                        key={appointment.id}
                        appointmentId={appointment.id}
                      />
                    );
                  }

                  return (
                    <AgendaTimelineGroup key={index}>
                      {appointments.map((a) => (
                        <AppointmentAgendaTimelineItem
                          key={a!.id}
                          appointmentId={a!.id}
                        />
                      ))}
                    </AgendaTimelineGroup>
                  );
                })}
              </AgendaTimeline>
              {loading && loadingAppointmentCountData && <AppointmentsLoader />}
              {!displayEmptyHomePageState &&
                data?.appointments?.length === 0 && (
                  <NoAppointmentPlaceholder />
                )}
              {displayEmptyHomePageState && <EmptyHomePageState />}
            </Box>
          </Box>
        </div>
      </ReskinContent>
      <DetailsSidebar breakpointWidth={1260}>
        <div className="relative w-auto">
          <div className="flex w-80 flex-col gap-8 p-4">
            <div className="bg-gray-1 rounded-lg shadow-md">
              <Calendar mode="single" selected={date} onSelect={setDate} />
            </div>

            {isSupportedBrowser && <EhrDataImporter />}
            {isSupportedBrowser && <EhrOnboarding />}

            {isTenantAdmin && (
              <FeatureFlaggedComponent flag="LOCATIONS">
                <Link className="underline" to="../admin">
                  Tenant Settings
                </Link>
              </FeatureFlaggedComponent>
            )}
          </div>
        </div>
      </DetailsSidebar>
    </ReskinMain>
  );
};
