import { P } from '@eluve/components';
import { formatTo12HourTime } from '@eluve/date-utils';
import { useAppointmentStartedAt } from '@eluve/frontend-appointment-hooks';

export const SessionStartedAtTimestamp: React.FC = () => {
  const startedAt = useAppointmentStartedAt();
  return (
    <div>
      {startedAt && (
        <div className="px-2 pb-3">
          <P className="text-lg">
            Session started at {formatTo12HourTime(startedAt)}
          </P>
        </div>
      )}
    </div>
  );
};
