import { useEffect, useState } from 'react';

import { getAudioDurationFromFile } from './getAudioDurationFromFile';
import {
  UserFileSystem,
  useUserFileSystemStore,
} from './user-file-system.store';

export const useTotalAudioDurationFromLocalFiles = (
  tenantId: string,
  appointmentId: string,
) => {
  const userFileSystem = useUserFileSystemStore(
    (state) => state.userFileSystem,
  );
  const [totalDuration, setTotalDuration] = useState(0);

  useEffect(
    function calculateTotalDuration() {
      (async () => {
        let updatedTotalDuration = 0;
        if (userFileSystem.type === 'available' && tenantId && appointmentId) {
          const files = await getLocalAppointmentFiles(
            userFileSystem,
            appointmentId,
          );
          for (const file of files) {
            const duration = await getAudioDurationFromFile(file);
            if (duration) {
              updatedTotalDuration += duration;
            }
          }
          setTotalDuration(updatedTotalDuration);
        }
      })();
    },
    [userFileSystem, tenantId, appointmentId],
  );

  return totalDuration;
};

const getLocalAppointmentFiles = async (
  fileSystem: UserFileSystem,
  appointmentId: string,
) => {
  const files: File[] = [];
  if (fileSystem.type !== 'available') {
    return files;
  }
  try {
    const appointmentDir =
      await fileSystem.appointmentsDir.getDirectoryHandle(appointmentId);

    for await (const entry of appointmentDir.values()) {
      const file = await entry.getFile();
      if (file.size > 0) {
        files.push(file);
      }
    }
    return files;
  } catch (error) {
    return files;
  }
};
