import { useCompleteFragment } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';

export const appointmentTotalDurationFragment = graphql(`
  fragment appointmentTotalDuration on Appointments @_unmask {
    totalDuration
  }
`);

export const useAppointmentTotalDuration = (appointmentId: string) => {
  const data = useCompleteFragment({
    fragment: appointmentTotalDurationFragment,
    key: {
      id: appointmentId,
    },
  });

  return data?.totalDuration ?? 0;
};
