import { Main, PageLayout, PageTitle } from '@eluve/components';

import { TenantsHeader } from './components/TenantsHeader';
import { TenantsList } from './components/TenantsList';

export const TenantsPage = () => {
  return (
    <PageLayout>
      <TenantsHeader />
      <Main className="px-3 pb-6">
        <PageTitle className="border-gray-5 border-b p-2 md:p-4">
          Select a Tenant
        </PageTitle>
        <TenantsList />
      </Main>
    </PageLayout>
  );
};
