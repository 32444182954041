import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Plus, Trash } from 'lucide-react';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { z } from 'zod';

import {
  Button,
  Form,
  FormField,
  FormItem,
  FormMessage,
  H2,
  H4,
  Input,
  Label,
  PageTitle,
  RadioGroup,
  RadioGroupItem,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
} from '@eluve/components';
import {
  EvalSourceArtifactTypeLookup,
  EvalSourceArtifactTypesEnum,
} from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';
import { useNamedLogger } from '@eluve/logger';

const createArtifactMutation = graphql(`
  mutation createArtifact($input: EvalSourceArtifactInsertInput!) {
    insertEvalSourceArtifactOne(object: $input) {
      __typename
      id
      type
      createdAt
      content
    }
  }
`);

const createArtifactFormSchema = z.object({
  type: z.string().refine((v) => v in EvalSourceArtifactTypeLookup),
  name: z.string().min(3),
  description: z.string(),
  content: z.string().min(10),
  facts: z.array(
    z.object({
      fact: z.string().min(3),
      multiplier: z.coerce.number().max(1),
      expectedOutput: z.coerce.boolean(),
    }),
  ),
});

type FormData = z.infer<typeof createArtifactFormSchema>;

const defaultValues: Partial<FormData> = {
  name: '',
  description: '',
  content: '',
  facts: [],
};

export const CreateArtifactPage: React.FC = () => {
  const logger = useNamedLogger('create-artifact-page');
  const navigate = useNavigate();

  const form = useForm<FormData>({
    resolver: zodResolver(createArtifactFormSchema),
    defaultValues,
    mode: 'all',
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'facts',
  });

  const [createArtifact] = useMutation(createArtifactMutation, {
    onCompleted: (data) => {
      toast.success('Successfully created artifact');
      form.reset(defaultValues);

      if (data?.insertEvalSourceArtifactOne) {
        navigate(
          `/admin/fact-verification/artifacts/${data.insertEvalSourceArtifactOne.id}`,
        );
      }
    },
    onError: () => toast.error('Failed to create artifact'),
  });

  const onSubmit = async ({
    content,
    description,
    facts,
    name,
    type,
  }: FormData) => {
    await createArtifact({
      variables: {
        input: {
          name,
          content,
          description,
          type: type as EvalSourceArtifactTypesEnum,
          facts: {
            data: facts.map((f) => ({
              statement: f.fact,
              weightMultiplier: f.multiplier,
              expectedOutput: f.expectedOutput,
            })),
          },
        },
      },
    });
  };

  return (
    <div className="max-w-screen-xl">
      <PageTitle>Create an Artifact</PageTitle>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit, () => {
            toast.error('Form failed validation');
            logger.debug('Invalid form submission attempted');
          })}
        >
          <H2>Info</H2>

          <FormField
            control={form.control}
            name="type"
            render={({ field }) => (
              <FormItem className="max-w-lg">
                <label htmlFor="type" className="sr-only">
                  Artifact Type
                </label>

                <Select value={field.value} onValueChange={field.onChange}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select artifact type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {Object.keys(EvalSourceArtifactTypeLookup).map((type) => (
                        <SelectItem key={type} value={type}>
                          {type}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="name"
            render={({ field, fieldState: { error } }) => (
              <FormItem className="max-w-lg">
                <label htmlFor="name" className="sr-only">
                  Name
                </label>
                <Input className="bg-white/5" placeholder="Name" {...field} />
                {error && (
                  <FormMessage className="mt-4">{error.message}</FormMessage>
                )}
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field, fieldState: { error } }) => (
              <FormItem className="max-w-lg">
                <label htmlFor="description" className="sr-only">
                  Description
                </label>
                <Textarea
                  className="bg-white/5"
                  placeholder="Description"
                  {...field}
                />
                {error && (
                  <FormMessage className="mt-4">{error.message}</FormMessage>
                )}
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="content"
            render={({ field, fieldState: { error } }) => (
              <FormItem>
                <label htmlFor="description" className="sr-only">
                  Description
                </label>
                <Textarea
                  className="bg-white/5"
                  placeholder="Content"
                  rows={10}
                  {...field}
                />
                {error && (
                  <FormMessage className="mt-4">{error.message}</FormMessage>
                )}
              </FormItem>
            )}
          />
          <div className="flex items-center">
            <H2 className="mt-2">Facts</H2>
          </div>
          <H4 className="font-thin">
            Provide at least one statement about the content above. Indicate if
            the statement is true or false. Provide a weight multiplier between
            0 and 1. Default is 0.5. 1 means it's critically important and 0
            means it's irrelevant.
          </H4>
          <div className="grid gap-2">
            {fields.map((field, index) => (
              <div className="flex items-center gap-2" key={field.id}>
                <Textarea
                  key={`${field.id}.fact`} // important to include key with field's id
                  {...form.register(`facts.${index}.fact`)}
                />

                <Input
                  type="number"
                  step="0.1"
                  key={`${field.id}.multiplier`}
                  className="max-w-[80px]"
                  {...form.register(`facts.${index}.multiplier`)}
                />

                <RadioGroup
                  {...form.register(`facts.${index}.expectedOutput`)}
                  className="flex gap-2"
                  defaultValue="true"
                >
                  <RadioGroupItem value={'true'} />
                  <Label>True</Label>
                  <RadioGroupItem value={'false'} />
                  <Label>False</Label>
                </RadioGroup>

                <Button
                  variant="destructive"
                  size="icon"
                  disabled={fields.length === 1}
                  onClick={() => remove(index)}
                >
                  <Trash />
                </Button>
              </div>
            ))}
            <Button
              className="mt-2 w-fit px-4"
              size="lg"
              type="button"
              variant="secondary"
              onClick={() =>
                append({
                  fact: '',
                  multiplier: 0.5,
                  expectedOutput: true,
                })
              }
            >
              <Plus className="mr-2" />
              Add a fact
            </Button>

            <div className="border-gray-4 border-b-2 py-4"></div>

            <Button className="mt-8 w-fit" disabled={!form.formState.isDirty}>
              Create Artifact
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};
