import { RefreshCcw } from 'lucide-react';
import React from 'react';

import { useApiClient } from '@eluve/api-client-provider';
import { useDialog } from '@eluve/blocks';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  H2,
  P,
  useToast,
} from '@eluve/components';
import {
  useAppointmentId,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import { useAppointmentTranscript } from '@eluve/frontend-appointment-hooks';
import { useAppointmentActivity } from '@eluve/frontend-appointment-hooks';
import { useNamedLogger } from '@eluve/logger';
import { useCaptureEventWithSharedContext } from '@eluve/posthog-react';
import {
  useTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';

import { TemplateSelectorWithAutoSelectOption } from './TemplateSelectorWithAutoSelectOption';

export interface RegenerateSummaryButtonProps {}

export const RegenerateSummaryButton: React.FC<
  RegenerateSummaryButtonProps
> = () => {
  const logger = useNamedLogger('RegenerateSummaryButton');
  const { isDialogOpen, toggleDialog, setIsDialogOpen } = useDialog();

  const userId = useUserIdFromSession();
  const tenantId = useTenantIdFromParams();
  const currentSummary = useSummary();
  const transcript = useAppointmentTranscript();
  const apiClient = useApiClient();
  const appointmentId = useAppointmentId();

  const captureEvent = useCaptureEventWithSharedContext({
    tenantId,
    appointmentId,
    llmOutputId: currentSummary.llmOutputId,
  });

  const { isSummarizationInProgress, isTranscriptionInProgress } =
    useAppointmentActivity();

  const { toast } = useToast();

  const regenerationDisabled =
    isSummarizationInProgress || isTranscriptionInProgress;

  const handleRegenerate = async () => {
    toggleDialog();

    logger.info('Regenerate Summary Button Clicked', {
      userId,
      tenantId,
      appointmentId,
      llmOutputId: currentSummary.llmOutputId,
    });

    captureEvent('user_regenerated_note_summary', {
      llmOutputId: currentSummary.llmOutputId,
    });

    try {
      const result = await apiClient.llm.summarizeAppointment({
        body: {},
        params: {
          tenantId: tenantId!,
          appointmentId,
        },
      });

      if (result.status !== 201) {
        throw new Error('Failed to start summarization job');
      }

      toast({
        variant: 'success',
        title: 'Regenerating your summary',
        description: 'Your new summary should be available soon',
        duration: 4 * 1000,
      });
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Failed to regenerate summary',
        description: 'Please try again',
        duration: 4 * 1000,
      });
    }

    return;
  };

  if (transcript.length < 300) {
    return null;
  }

  return (
    <>
      <Button
        variant="outlinesecondary"
        disabled={regenerationDisabled}
        onClick={() => {
          captureEvent('user_opened_regenerate_note_summary_dialog');
          toggleDialog();
        }}
        size={'xs'}
      >
        Regenerate summary
      </Button>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="flex flex-col gap-5 p-8">
          <H2>Regenerating your summary</H2>
          <P>This will overwrite the current summary.</P>
          <div className="bg-gray-5 h-[0.5px] w-full justify-between rounded-sm" />

          <div className="flex flex-col gap-1">
            <P>Your new note will be generated with the following template:</P>
            <div className="flex  w-full justify-between rounded-b-xl">
              <TemplateSelectorWithAutoSelectOption
                appointmentId={appointmentId}
              />
            </div>
          </div>
          <DialogFooter className="w-full flex-row justify-end gap-2">
            <Button
              variant="ghost"
              className="bg-gray-3 hover:bg-gray-4 w-fit"
              onClick={toggleDialog}
            >
              Cancel
            </Button>
            <Button onClick={handleRegenerate} className="w-fit">
              <RefreshCcw className="h-4 pr-1" />
              Regenerate
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
