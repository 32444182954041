import { useQuery } from '@apollo/client';

import { getTenantLocationUsersQuery } from '@eluve/frontend-feature-location-hierarchy';
import {
  useTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';

export const useIsUserPartOfAnyLocation = () => {
  const tenantId = useTenantIdFromParams();
  const userId = useUserIdFromSession();
  const { data } = useQuery(getTenantLocationUsersQuery, {
    variables: {
      tenantId: tenantId!,
    },
    skip: !tenantId,
  });
  const isUserPartOfAnyLocation = data?.tenantsByPk?.locations?.some(
    (location) =>
      location.location_users?.some((user) => user.userId === userId),
  );

  return isUserPartOfAnyLocation;
};
