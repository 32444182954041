import { z } from 'zod';

export const patientSchema = z.object({
  id: z.number(),
  first_name: z.string().nullish().default(''),
  last_name: z.string().nullish().default(''),
  middle_name: z.unknown(),
  name: z.string().nullish(),
  email: z.string().nullish(),
  home_phone: z.string().nullish(),
  work_phone: z.string().nullish(),
  mobile_phone: z.string().nullish(),
  prefix: z.unknown(),
  addressable_name: z.string().nullish(),
  fax_phone: z.unknown(),
  street_address: z.unknown(),
  street_address_2: z.unknown(),
  city: z.string().nullish(),
  province: z.string().nullish(),
  postal: z.unknown(),
  country: z.string().nullish(),
  personal_health_number: z.unknown(),
  dob: z.string().nullish(),
  member_since: z.string().nullish(),
  patient_number: z.string().nullish(),
  send_notifications: z.boolean().nullish(),
  send_sms_notifications: z.boolean().nullish(),
  email_wait_list_notifications: z.boolean().nullish(),
  sms_wait_list_notifications: z.boolean().nullish(),
  reminder_type_ids: z.array(z.number().nullish()),
  last_login: z.unknown(),
  msp_eligible: z.string().nullish(),
  guardian_name: z.unknown(),
  referral_source: z.unknown(),
  referring_staff_member_id: z.unknown(),
  marketing_emails: z.boolean().nullish(),
  gender: z.unknown(),
  identifying_gender: z.unknown(),
  do_not_email: z.boolean().nullish(),
  ratings_emails: z.boolean().nullish(),
  secure_messages_email: z.boolean().nullish(),
  secure_messages_sms: z.boolean().nullish(),
  preferred_name: z.unknown(),
  preferred_first_name: z.string().nullish(),
  all_names: z.string().nullish(),
  pronouns: z.unknown(),
  occupation: z.unknown(),
  employer: z.unknown(),
  staff_member_id: z.unknown(),
  staff_user: z.boolean().nullish(),
  emergency_contact_name: z.unknown(),
  emergency_contact_phone: z.unknown(),
  emergency_contact_relationship: z.unknown(),
  referring_professional: z.unknown(),
  referring_professional_phone: z.unknown(),
  referring_professional_email: z.unknown(),
  family_doctor: z.unknown(),
  family_doctor_phone: z.unknown(),
  family_doctor_email: z.unknown(),
  medical_alert: z.unknown(),
  discharged: z.boolean().nullish(),
  deceased: z.boolean().nullish(),
  online_booking_policy: z.unknown(),
  online_booking_payment_policy: z.unknown(),
  secure_messages_policy: z.unknown(),
  secure_messages_enabled: z.boolean().nullish(),
  primary_phone: z.string().nullish(),
  selected_reminder_types: z.array(
    z
      .object({
        id: z.number().nullish(),
        ok: z.boolean().nullish(),
        name: z.string().nullish(),
        description: z.string().nullish(),
        errors: z.object({}),
      })
      .passthrough(),
  ),
  last_appointment: z
    .object({
      id: z.number().nullish(),
      start_at: z.string().nullish(),
      treatment_name: z.string().nullish(),
      staff_member_name: z.string().nullish(),
    })
    .passthrough()
    .nullish(),
  upcoming_appointments: z.array(z.unknown()),
  logins: z.array(
    z
      .object({ label: z.string().nullish(), username: z.unknown() })
      .passthrough(),
  ),
  intake_forms: z.array(z.unknown()).nullish(),
  pending_intake_forms: z.array(z.unknown()).nullish(),
  relationships: z.array(
    z
      .object({
        id: z.number().nullish(),
        relation_id: z.number().nullish(),
        owner_name: z.string().nullish(),
        owner_id: z.number().nullish(),
        relation_name: z.string().nullish(),
        name: z.unknown(),
        notify: z.boolean().nullish(),
        send_receipts: z.boolean().nullish(),
        book_online: z.boolean().nullish(),
        ical: z.boolean().nullish(),
        use_payment_methods: z.boolean().nullish(),
        bill_payments: z.boolean().nullish(),
        relation_all_names: z.string().nullish(),
        owner_all_names: z.string().nullish(),
      })
      .passthrough(),
  ),
  received_relationships: z.array(
    z
      .object({
        id: z.number().nullish(),
        relation_id: z.number().nullish(),
        owner_name: z.string().nullish(),
        owner_id: z.number().nullish(),
        relation_name: z.string().nullish(),
        name: z.unknown(),
        notify: z.boolean().nullish(),
        send_receipts: z.boolean().nullish(),
        book_online: z.boolean().nullish(),
        ical: z.boolean().nullish(),
        use_payment_methods: z.boolean().nullish(),
        bill_payments: z.boolean().nullish(),
        relation_all_names: z.string().nullish(),
        owner_all_names: z.string().nullish(),
      })
      .passthrough(),
  ),
  default_adjustments: z.array(z.unknown()),
  passes: z.array(z.unknown()),
  credit_card_details: z.array(
    z
      .object({
        card_type: z.string().nullish(),
        card_suffix: z.string().nullish(),
        expiry_year: z.number().nullish(),
        expiry_month: z.number().nullish(),
        token: z.string().nullish(),
        nickname: z.unknown(),
        color: z.unknown(),
      })
      .passthrough(),
  ),
  notes: z.array(
    z.union([
      z
        .object({
          id: z.number().nullish(),
          text: z.string().nullish(),
          created_at: z.string().nullish(),
          updated_at: z.string().nullish(),
          archived_at: z.unknown(),
          hidden_at: z.unknown(),
          flagged_at: z.string().nullish(),
          author_name: z.string().nullish(),
          patient_name: z.string().nullish(),
          notable_description: z.string().nullish(),
          type: z.string().nullish(),
          author_type: z.string().nullish(),
          author_id: z.number().nullish(),
          notable_id: z.number().nullish(),
          notable_type: z.string().nullish(),
          patient_id: z.number().nullish(),
          task: z.boolean().nullish(),
          due_at: z.unknown(),
          completed_at: z.unknown(),
          assignee_id: z.unknown(),
          assignee_name: z.unknown(),
          completer_id: z.unknown(),
          completer_name: z.unknown(),
          task_name: z.unknown(),
          notable_js_app_path: z.string().nullish(),
          header: z.unknown(),
          footer: z.unknown(),
        })
        .passthrough(),
      z
        .object({
          id: z.number().nullish(),
          text: z.string().nullish(),
          created_at: z.string().nullish(),
          updated_at: z.string().nullish(),
          archived_at: z.unknown(),
          hidden_at: z.unknown(),
          flagged_at: z.unknown(),
          author_name: z.string().nullish(),
          patient_name: z.string().nullish(),
          notable_description: z.string().nullish(),
          type: z.string().nullish(),
          author_type: z.string().nullish(),
          author_id: z.number().nullish(),
          notable_id: z.number().nullish(),
          notable_type: z.string().nullish(),
          patient_id: z.number().nullish(),
          task: z.unknown(),
          due_at: z.unknown(),
          completed_at: z.unknown(),
          assignee_id: z.unknown(),
          assignee_name: z.unknown(),
          completer_id: z.unknown(),
          completer_name: z.unknown(),
          task_name: z.unknown(),
          notable_js_app_path: z.string().nullish(),
          header: z.unknown(),
          footer: z.unknown(),
        })
        .passthrough(),
      z
        .object({
          id: z.number().nullish(),
          text: z.string().nullish(),
          created_at: z.string().nullish(),
          updated_at: z.string().nullish(),
          archived_at: z.unknown(),
          hidden_at: z.unknown(),
          flagged_at: z.unknown(),
          author_name: z.string().nullish(),
          patient_name: z.string().nullish(),
          notable_description: z.string().nullish(),
          type: z.string().nullish(),
          author_type: z.string().nullish(),
          author_id: z.number().nullish(),
          notable_id: z.number().nullish(),
          notable_type: z.string().nullish(),
          patient_id: z.number().nullish(),
          task: z.boolean().nullish(),
          due_at: z.unknown(),
          completed_at: z.unknown(),
          assignee_id: z.unknown(),
          assignee_name: z.unknown(),
          completer_id: z.unknown(),
          completer_name: z.unknown(),
          task_name: z.unknown(),
          notable_js_app_path: z.string().nullish(),
          header: z.unknown(),
          footer: z.unknown(),
        })
        .passthrough(),
    ]),
  ),
  photo: z.unknown(),
  photo_lg: z.unknown(),
  appointments_count: z.number().nullish(),
  upcoming_appointments_count: z.number().nullish(),
  active_booking_reminders_count: z.number().nullish(),
  no_show_count: z.number().nullish(),
  chart_entries_count: z.number().nullish(),
  relationships_count: z.number().nullish(),
  email_status: z.string().nullish(),
  email_ok: z.boolean().nullish(),
  email_intake_form_ok: z.boolean().nullish(),
  email_notify_ok: z.boolean().nullish(),
  mobile_status: z.string().nullish(),
  mobile_ok: z.boolean().nullish(),
  balance: z.number().nullish(),
  available_credit: z.number().nullish(),
  amount_owing: z.number().nullish(),
  claims_amount_owing: z.number().nullish(),
  loyalty_balance: z.number().nullish(),
  additional_receipt_recipients: z.boolean().nullish(),
  last_payment_reminder: z.unknown(),
});

export const appointmentSchema = z.object({
  id: z.number(),
  location_id: z.number().nullish(),
  room_id: z.unknown(),
  resource_ids: z.array(z.unknown()),
  start_at: z.string(),
  end_at: z.string().nullish().default(''),
  patient_id: z.number().nullish(),
  primary_patient_id: z.number().nullish(),
  staff_member_id: z.number().nullish(),
  staff_member_name: z.string().nullish(),
  treatment_id: z.number().nullish(),
  archived_at: z.string().nullish(),
  no_show_at: z.unknown(),
  cancelled_at: z.unknown(),
  booked_at: z.string().nullish(),
  break: z.boolean().nullish(),
  state: z.string().nullish(),
  additional_states: z.array(z.unknown()),
  arrived_at: z.unknown(),
  checked_in_at: z.unknown(),
  patient_name: z.string().nullish(),
  patient_is_primary: z.boolean().nullish(),
  primary_patient_name: z.string().nullish(),
  patient: z
    .object({
      prefix: z.string().nullish(),
      first_name: z.string().nullish().default(''),
      preferred_first_name: z.string().nullish(),
      last_name: z.string().nullish().default(''),
      dob: z.string().nullish(),
    })
    .passthrough(),
  primary_patient: z
    .object({
      prefix: z.string().nullish(),
      first_name: z.string().nullish(),
      preferred_first_name: z.string().nullish(),
      last_name: z.string().nullish(),
      dob: z.string().nullish(),
    })
    .passthrough()
    .nullish(),
  patient_video_session_state: z.unknown(),
  treatment_name: z.string().nullish().default(''),
  color: z.string().nullish(),
  capacity: z.number().nullish(),
  class_booking: z.boolean().nullish(),
  parent_appointment_id: z.unknown(),
  participant_count: z.number().nullish(),
  arrived_participant_count: z.number().nullish(),
  purchase_id: z.number().nullish(),
  sale_id: z.number().nullish(),
  purchased: z.boolean().nullish(),
  purchase_state: z.string().nullish(),
  patient_paid: z.boolean().nullish(),
  has_notes: z.boolean().nullish(),
  notes_text: z.string().nullish(),
  first_visit: z.boolean().nullish(),
  insurance_state: z.unknown(),
  updated_at: z.string().nullish(),
  chart_status: z.string().nullish(),
  reschedulable: z.boolean().nullish(),
  order_id: z.number().nullish(),
  order_item_id: z.number().nullish(),
  pass: z.object({ quantity_interval: z.unknown() }).passthrough().nullish(),
  pass_id: z.unknown(),
  child_appointment_ids: z.array(z.unknown()),
  original_appointment_id: z.unknown(),
  rescheduled: z.boolean().nullish(),
  cancelled: z.boolean().nullish(),
  online_only: z.boolean().nullish(),
  wait_list_block: z.boolean().nullish(),
  booking_type: z.string().nullish(),
  group_booking: z.boolean().nullish(),
});

export const chartPartSchema = z
  .object({
    id: z.number().nullish(),
    type: z.string().nullish(),
    template: z.string().nullish(),
    image_id: z.any().nullish(),
    has_image: z.boolean().nullish(),
    signed_at: z.any().nullish(),
    description: z.string().nullish(),
    image_name: z.any().nullish(),
    required: z.boolean().nullish(),
    scorable: z.boolean().nullish(),
    part_order_position: z.number().nullish(),
    chart_entry_id: z.number().nullish(),
    text: z.string().nullish(),
    label: z.string().nullish(),
    text_delta: z
      .object({
        ops: z
          .array(
            z
              .object({
                insert: z.any().nullish(),
              })
              .passthrough(),
          )
          .nullish(),
      })
      .passthrough()
      .nullish(),
    value: z.unknown().nullish(),
    slider_labels: z.array(z.string()).nullish(),
    score_hash: z.any().nullish(),
    checkbox_layout: z.string().nullish(),
    visible_options: z.array(z.string()).nullish(),
    include_notes: z.boolean().nullish(),
    hide_unchecked: z.boolean().nullish(),
    options: z.array(z.string()).nullish(),
    selected_options: z.array(z.string()).nullish(),
    notes_hash: z.any().nullish(),
  })
  .passthrough();

export const deidentifiedChartSchema = z
  .object({
    appointmentId: z.number().nullish(),
    patientId: z.number().nullish(),
    codes: z
      .array(
        z
          .object({
            codeType: z.string().optional(),
            id: z.string().optional(),
            code: z.string().optional(),
            type: z.string().optional(),
            label: z.string().optional(),
            amount: z.string().optional(),
            identifier: z.string().optional(),
          })
          .passthrough(),
      )
      .optional(),
    summary: z.string().optional(),
  })
  .passthrough();

export const chartEntrySchema = z
  .object({
    id: z.number(),
    discipline_id: z.number().nullish(),
    signed_state: z.string().nullish(),
    viewable_by: z.any().nullish(),
    staff_member_name: z.string().nullish(),
    patient_id: z.number().nullish(),
    patient_name: z.string().nullish(),
    display_to_patient: z.boolean().nullish(),
    title: z.string().nullish(),
    author_type: z.string().nullish(),
    author_id: z.number().nullish(),
    author_name: z.string().nullish(),
    starred_at: z.any().nullish(),
    outcome_measure_id: z.any().nullish(),
    entered_on: z.string().nullish(),
    signed_at: z.any().nullish(),
    archived_at: z.any().nullish(),
    created_at: z.string().nullish(),
    appointment_id: z.number().nullish(),
    formatted_signature_for_author: z.string().nullish(),
    category: z.string().nullish(),
    treatment_plan_id: z.any().nullish(),
    treatment_plan: z.any().nullish(),
    chart_parts: z.array(chartPartSchema).nullish(),
    snippet: z.string().nullish(),
    patient_email_ok: z.boolean().nullish(),
    intake_form_id: z.any().nullish(),
  })
  .passthrough();

export const chartResponseSchema = z
  .object({
    total_entries: z.number(),
    chart_entries: z.array(chartEntrySchema),
  })
  .passthrough();

export const appointmentsResponseSchema = z
  .object({
    staff_member_ids: z.array(z.number().nullish()),
    appointments: z.array(appointmentSchema),
    shifts: z.array(z.unknown()),
    openings: z.array(z.unknown()),
  })
  .passthrough();

export type DeidentifiedChart = z.infer<typeof deidentifiedChartSchema>;
export type ChartEntry = z.infer<typeof chartEntrySchema>;
export type ChartResponse = z.infer<typeof chartResponseSchema>;
export type Appointment = z.infer<typeof appointmentSchema>;
export type AppointmentsResponse = z.infer<typeof appointmentsResponseSchema>;
export type Patient = z.infer<typeof patientSchema>;
