import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { supportedVendorsSchema } from '@eluve/graphql-types';

const c = initContract();

const importPatientsSchema = z.object({
  data: z.array(z.record(z.unknown())),
  vendor: supportedVendorsSchema,
  domain: z.string(),
});

export type ImportPatientsRequest = z.infer<typeof importPatientsSchema>;

export const patientsContract = c.router(
  {
    importPatient: {
      method: 'POST',
      path: '/',
      responses: {
        201: z.object({
          id: z.string(),
        }),
      },
      body: z.object({
        data: z.object({
          chart: z.record(z.unknown()),
        }),
        vendor: supportedVendorsSchema,
        domain: z.string(),
      }),
      summary: 'Import single patient',
    },
    importPatients: {
      method: 'POST',
      path: '/bulk-import',
      responses: {
        201: z.object({
          ids: z.array(z.string()),
        }),
      },
      body: importPatientsSchema,
      summary: 'Import patients in bulk',
    },
  },
  {
    pathPrefix: '/:tenantId/patients',
  },
);
