import { useApolloClient, useQuery } from '@apollo/client';
import {
  ArrowRightLeft,
  CalendarSearch,
  CircleHelpIcon,
  Users,
} from 'lucide-react';
import { usePostHog } from 'posthog-js/react';
import React, { useEffect, useMemo } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';

import {
  EluveLogo,
  ReskinLayout,
  Sidebar,
  SidebarNav,
  SidebarNavItem,
  SidebarNavSpacer,
} from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';
import { useUserIdFromSession } from '@eluve/session-helpers';
import { FeatureFlaggedComponent, useTenantName } from '@eluve/smart-blocks';

import { UserHeaderDropdownMenu } from '../components/UserHeaderDropdownMenu';
import { GetTenantsList } from '../operations';

export const TenantLayout: React.FC = () => {
  const logger = useNamedLogger('TenantLayout');
  const userId = useUserIdFromSession();
  const { tenantId } = useParams() as { tenantId: string };
  const client = useApolloClient();
  const postHog = usePostHog();
  const localStorageKey = `last-tenant-id-for-user-${userId}`;

  const lastTenantId = useMemo(
    () => localStorage.getItem(localStorageKey),
    [localStorageKey],
  );

  useEffect(() => {
    // If we're switching tenants we should clear the Apollo cache
    // so that it doesn't contain any stale data from the previous tenant
    if (tenantId !== lastTenantId) {
      logger.info(`Switching last tenant. Clearing apollo cache`, {
        lastTenantId,
        tenantId,
      });
      localStorage.setItem(localStorageKey, tenantId);
      client.resetStore();
    }
  }, [tenantId, lastTenantId, client, logger, localStorageKey]);

  const { data: userTenants } = useQuery(GetTenantsList);
  const tenantName = useTenantName(tenantId);

  useEffect(() => {
    postHog.group('tenant', tenantId, {
      name: tenantName,
    });
  }, [postHog, tenantId, tenantName]);

  return (
    <ReskinLayout>
      <Sidebar>
        <SidebarNav>
          <Link to="/">
            <SidebarNavItem className="mb-2" icon={<EluveLogo />}>
              Eluve Inc.
            </SidebarNavItem>
          </Link>
          {(userTenants?.assignedTenantUsers?.length ?? 0) > 1 && (
            <Link to="/tenants">
              <SidebarNavItem icon={<ArrowRightLeft />}>
                {tenantName}
              </SidebarNavItem>
            </Link>
          )}

          <FeatureFlaggedComponent flag="APPOINTMENTS_VIEW">
            <Link to={`/tenants/${tenantId}/appointments`}>
              <SidebarNavItem icon={<CalendarSearch />}>
                Appointments
              </SidebarNavItem>
            </Link>
          </FeatureFlaggedComponent>
          <FeatureFlaggedComponent flag="PATIENTS_VIEW">
            <Link to={`/tenants/${tenantId}/patients`}>
              <SidebarNavItem icon={<Users />}>Patients</SidebarNavItem>
            </Link>
          </FeatureFlaggedComponent>
        </SidebarNav>
        <SidebarNavSpacer />
        <SidebarNav>
          <a
            href="https://eluveinc.notion.site/Eluve-Support-e7de10ae63384ede8e48a062467bee81"
            target="_blank"
            rel="noreferrer"
          >
            <SidebarNavItem icon={<CircleHelpIcon />}>Support</SidebarNavItem>
          </a>
          <SidebarNavItem icon={<UserHeaderDropdownMenu />}>
            User
          </SidebarNavItem>
        </SidebarNav>
      </Sidebar>
      <Outlet />
    </ReskinLayout>
  );
};
