import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { signIn, signUp } from 'supertokens-auth-react/recipe/emailpassword';
import { getAuthorisationURLWithQueryParamsAndSetState } from 'supertokens-auth-react/recipe/thirdparty';
import { match } from 'ts-pattern';
import { z } from 'zod';

import { useApiClient } from '@eluve/api-client-provider';
import { EmailPasswordAuthForm } from '@eluve/blocks';
import {
  Box,
  Button,
  EluveNameLogo,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  GoogleLogo,
  H3,
  Input,
  P,
  cn,
} from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';

import { appConfig } from '../config';

const LoginControls: React.FC<{
  initialEmail?: string;
  enableEmailPassword: boolean;
  enableSocial: boolean;
}> = ({ enableEmailPassword, enableSocial, initialEmail }) => {
  const navigate = useNavigate();

  return (
    <Box vStack className="w-full items-center">
      {enableEmailPassword && (
        <EmailPasswordAuthForm
          initialEmail={initialEmail}
          onSubmit={async ({ email, password, type }) => {
            const authMethod = type === 'login' ? signIn : signUp;
            const response = await authMethod({
              formFields: [
                {
                  id: 'email',
                  value: email,
                },
                {
                  id: 'password',
                  value: password,
                },
              ],
            });

            if (response.status === 'OK') {
              navigate('/');
            } else {
              toast.error('Sign in failed');
            }
          }}
        />
      )}
      {match([enableEmailPassword, enableSocial])
        .with([true, true], () => (
          <Box hStack className="my-2 w-full gap-2">
            <div className="bg-gray-4 h-0.5 w-full"></div>
            <P className="w-full flex-1 whitespace-nowrap">Or continue with</P>
            <div className="bg-gray-4 h-0.5 w-full"></div>
          </Box>
        ))
        .with([false, true], () => (
          <P className="mb-1">Select a provider to login</P>
        ))
        .otherwise(() => null)}
      {enableSocial && (
        <Box hStack>
          <Button
            variant="outline"
            size="lg"
            onClick={async () => {
              try {
                const authUrl =
                  await getAuthorisationURLWithQueryParamsAndSetState({
                    thirdPartyId: 'google',
                    frontendRedirectURI: `${window.location.origin}/login/callback/google`,
                  });

                window.location.assign(authUrl);
              } catch {
                toast.error('Sign in failed');
              }
            }}
          >
            <GoogleLogo className="mr-2" />
            Google
          </Button>
        </Box>
      )}
    </Box>
  );
};

const formSchema = z.object({
  email: z.string().email(),
});

export const LoginPage: React.FC = () => {
  const apiClient = useApiClient();
  const [search] = useSearchParams();
  const errorMessage = search.get('errorMessage') ?? '';
  const logger = useNamedLogger('LoginPage');

  const [isFallbackLogin, setIsFallbackLogin] = useState(false);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    if (errorMessage) {
      logger.warn(errorMessage);
      toast.error(errorMessage);
    }
  }, [errorMessage, logger]);

  const email = form.watch('email');

  const tryInitiateLogin = async (email: string) => {
    try {
      const response = await apiClient.auth.tryInitiateLogin({
        body: {
          email,
          appType: 'web',
        },
      });

      if (response.status !== 200) {
        throw new Error('tryInitiateLogin failed');
      }

      const authAction = match(response.body)
        .with({ type: 'saml' }, ({ redirectUrl }) => {
          return async () => {
            const isGoogleSaml = redirectUrl.includes('accounts.google.com');
            const finalRedirect = match(isGoogleSaml)
              .with(true, () => {
                // If logging in with Google SAML, force the user to the account
                // chooser page even if there is only one logged-in in their browser
                const encoded = encodeURIComponent(redirectUrl);
                return `https://accounts.google.com/AccountChooser?continue=${encoded}`;
              })
              .otherwise(() => redirectUrl);

            return window.location.assign(finalRedirect);
          };
        })
        .with({ type: 'social' }, ({ thirdPartyId }) => {
          return async () => {
            try {
              const authUrl =
                await getAuthorisationURLWithQueryParamsAndSetState({
                  thirdPartyId,
                  frontendRedirectURI: `${window.location.origin}/login/callback/${thirdPartyId}`,
                });

              // If logging in with Google OAuth, force the user to choose
              // the account to use even if there is only one logged-in in their browser
              const finalAuthUrl =
                thirdPartyId === 'google'
                  ? `${authUrl}&prompt=consent`
                  : authUrl;

              window.location.assign(finalAuthUrl);
            } catch {
              toast.error('Sign in failed');
            }
          };
        })
        .with({ type: 'email' }, () => {
          return async () => {
            setIsFallbackLogin(true);
          };
        })
        .exhaustive();

      await authAction();
    } catch {
      toast.error('Sign in failed');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && email) {
      form.handleSubmit((f) => tryInitiateLogin(f.email));
    }
  };

  return (
    <div className="grid h-screen place-items-center p-2">
      <Box vStack className="gap-0.5">
        <EluveNameLogo height={60} />
        <H3 className="ml-1 max-w-[512px] font-normal">
          An AI assistant that automatically does your clinical paperwork for
          you
        </H3>
        <div
          className={cn([
            'border-gray-5 mt-2 flex w-full flex-col gap-5 rounded-md border p-4 shadow-lg',
            'md:w-[512px]',
          ])}
        >
          {match(isFallbackLogin)
            .with(false, () => (
              <>
                <P className="text-gray-10 text-lg font-normal">
                  Get started by entering your email
                </P>
                <Form {...form}>
                  <form
                    className="w-full space-y-2"
                    onSubmit={form.handleSubmit((f) =>
                      tryInitiateLogin(f.email),
                    )}
                  >
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              placeholder="Email"
                              {...field}
                              onKeyDown={handleKeyDown}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <Button type="submit" className="w-full">
                      Submit
                    </Button>
                  </form>
                </Form>
              </>
            ))
            .otherwise(() => (
              <LoginControls
                initialEmail={email}
                enableEmailPassword={appConfig.VITE_ENABLE_EMAIL_PASSWORD_AUTH}
                enableSocial={appConfig.VITE_ENABLE_SOCIAL_AUTH}
              />
            ))}

          <P>
            Not a member yet?{' '}
            <Link to="/waitlist" className="underline">
              Join our waitlist
            </Link>{' '}
            to be notified when we launch
          </P>
        </div>
      </Box>
    </div>
  );
};
