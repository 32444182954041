import some from 'lodash/some';
import { useSearchParams } from 'react-router-dom';
import { match } from 'ts-pattern';

import { Box, Button, H2 } from '@eluve/components';
import { urlSafeDate } from '@eluve/date-utils';
import { useAppointmentCounts } from '@eluve/frontend-appointment-hooks';
import { useCaptureEventOnMount } from '@eluve/posthog-react';
import { useTenantIdFromParams } from '@eluve/session-helpers';
import { useEluveExtExists } from '@eluve/smart-blocks';

import { appConfig } from '../../../../../config';
import { useIsUserPartOfAnyLocation } from '../hooks/useIsUserPartOfAnyLocation';
import { useStartAnonymousSession } from '../hooks/useStartAnonymousSession';
import { useEhrStatusDetails } from '../useEhrStatusDetails';
import { useImportDataFromEhr } from '../useImportDataFromEhr';

const TroubleShootingLink =
  'https://eluveinc.notion.site/Troubleshooting-Importing-from-your-EHR-fa0025839396416498427764b1553dbb';

const getEventName = (event: string) => ['empty_home_state', event].join('_');

const PromptToFirstTimeImport: React.FC = () => {
  const [_searchParams, setSearchParams] = useSearchParams();
  const startAnonymousSession = useStartAnonymousSession();

  useCaptureEventOnMount(getEventName('prompt_to_first_time_import_shown'));

  return (
    <Box center className="h-full w-full">
      <div className="flex flex-col items-center">
        <H2 className="font-normal text-black">No upcoming appointments</H2>
        <div className="w-112 flex flex-col">
          <div>
            <span className="text-gray-10 flex items-center p-3 text-center text-sm">
              Finish setting up your EHR integration to sync your appointments
              with Eluve.
            </span>
            <div className="mt-10 flex flex-col items-center">
              <div>
                <Button
                  variant="lightgray"
                  className="m-1"
                  onClick={() => {
                    setSearchParams({ event: 'onboarding' });
                  }}
                >
                  Import Appointments
                </Button>
                <Button
                  variant="lightgray"
                  className="m-1"
                  onClick={startAnonymousSession}
                >
                  <span className="text- sr-only">
                    Start a recording session
                  </span>
                  New Session
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

const PromptToRefresh: React.FC = () => {
  const [_searchParams] = useSearchParams();
  const tenantId = useTenantIdFromParams();
  const { importDataFromEhr, isDataImporting } = useImportDataFromEhr(
    tenantId!,
  );

  useCaptureEventOnMount(getEventName('prompt_to_refresh_shown'));

  return (
    <Box center className="h-full w-full">
      <div className="flex flex-col items-center">
        <H2 className="font-normal text-black">No upcoming appointments</H2>
        <div className="w-112 flex flex-col">
          <div>
            <span className="text-gray-10 flex items-center p-3 text-center text-sm">
              You don’t have any upcoming appointments for the next 7 days. If
              you recently added a new appointment on your EHR that is not
              showing up you can try refreshing.
            </span>
            <div className="mt-10 flex flex-col items-center">
              <div className="w-96">
                <Button
                  variant="lightgray"
                  className="m-1 w-full"
                  disabled={isDataImporting}
                  onClick={() => {
                    importDataFromEhr();
                  }}
                >
                  Refresh
                </Button>
              </div>
              <div>
                <Button
                  variant="link"
                  className="text-gray-10 m-1 underline"
                  onClick={() => {
                    window.open(
                      TroubleShootingLink,
                      '_blank',
                      'noopener,noreferrer',
                    );
                  }}
                >
                  Troubleshoot
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

const PromptToUpdate: React.FC = () => {
  useCaptureEventOnMount(getEventName('prompt_to_update_extension_shown'));

  return (
    <Box center className="h-full w-full">
      <div className="flex flex-col items-center">
        <H2 className="font-normal text-black">No upcoming appointments</H2>
        <div className="w-112 flex flex-col">
          <div>
            <span className="text-gray-10 flex items-center p-3 text-center text-sm">
              Please update your extension to get your latest appointment
              details.
            </span>
            <div className="mt-10 flex flex-col items-center">
              <div className="w-96">
                <Button
                  variant="lightgray"
                  className="m-1 w-full"
                  onClick={() => {
                    window.open(
                      'https://eluveinc.notion.site/Updating-the-Eluve-Extension-ffad42d228f14beeada0e06caffdc272',
                      '_blank',
                      'noopener,noreferrer',
                    );
                  }}
                >
                  Update Extension
                </Button>
              </div>
              <div>
                <Button
                  variant="link"
                  className="text-gray-10 m-1 underline"
                  onClick={() => {
                    window.open(
                      TroubleShootingLink,
                      '_blank',
                      'noopener,noreferrer',
                    );
                  }}
                >
                  Troubleshoot
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

const PromptToInstallExtension: React.FC = () => {
  const startAnonymousSession = useStartAnonymousSession();

  useCaptureEventOnMount(getEventName('prompt_to_install_shown'));

  return (
    <Box center className="h-full w-full">
      <div className="flex flex-col items-center">
        <H2 className="font-normal text-black">No upcoming appointments</H2>
        <div className="w-112 flex flex-col">
          <div>
            <span className="text-gray-10 flex items-center p-3 text-center text-sm">
              You don't have any appointments. Download the Eluve extension to
              get seamless integration.
            </span>
            <div className="mt-10 flex flex-col items-center">
              <div>
                <Button
                  variant="lightgray"
                  className="m-1"
                  onClick={() => {
                    window.open(
                      'https://chromewebstore.google.com/detail/eluve/hkekbppdbafnjcoeacbdghoebaeadlid',
                      '_blank',
                      'noopener,noreferrer',
                    );
                  }}
                >
                  Install Extension
                </Button>
                <Button
                  variant="lightgray"
                  className="m-1"
                  onClick={startAnonymousSession}
                >
                  <span className="text- sr-only">
                    Start a recording session
                  </span>
                  New Session
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export const EmptyHomePageState: React.FC = () => {
  const tenantId = useTenantIdFromParams();
  const { eluveExtExists, eluveExtVersionSatisfies } = useEluveExtExists();
  const [searchParams] = useSearchParams();
  const { data: ehrStatus } = useEhrStatusDetails();
  const { isSupportedBrowser } = useImportDataFromEhr(tenantId!);
  const fromDate = searchParams.get('date') ?? urlSafeDate();

  const isUserPartOfLocation = useIsUserPartOfAnyLocation();
  const isUserCurrentlyOnboarding = searchParams.get('event') === 'onboarding';

  const currentTenantEhrStatus =
    ehrStatus?.eluveDomains?.[appConfig.VITE_API_DOMAIN]?.tenants?.[tenantId!];
  const hasSyncedBefore = some(currentTenantEhrStatus?.vendors, (vendor) =>
    some(vendor?.domains, (domain) => Boolean(domain.syncedAt)),
  );

  const didUserFinishOnboarding = isUserPartOfLocation && hasSyncedBefore;
  const shouldPromptUserToFinishOnboarding =
    !didUserFinishOnboarding && !isUserCurrentlyOnboarding;

  const { totalAppointmentsCount, upcomingAppointmentsCount } =
    useAppointmentCounts({ days: 7, fromDate });

  return match({
    isSupportedBrowser,
    eluveExtExists,
    eluveExtVersionSatisfies,
    shouldPromptUserToFinishOnboarding,
    upcomingAppointmentsCount,
    totalAppointmentsCount,
  })
    .with({ eluveExtExists: false, isSupportedBrowser: true }, () => {
      return <PromptToInstallExtension />;
    })
    .with(
      {
        eluveExtExists: true,
        eluveExtVersionSatisfies: false,
      },
      () => {
        return <PromptToUpdate />;
      },
    )
    .with(
      {
        eluveExtExists: true,
        eluveExtVersionSatisfies: true,
        shouldPromptUserToFinishOnboarding: true,
      },
      () => {
        return <PromptToFirstTimeImport />;
      },
    )
    .with(
      {
        eluveExtExists: true,
        eluveExtVersionSatisfies: true,
        upcomingAppointmentsCount: 0,
        shouldPromptUserToFinishOnboarding: false,
      },
      () => {
        return <PromptToRefresh />;
      },
    )
    .otherwise(() => null);
};
