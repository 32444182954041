import { XIcon } from 'lucide-react';
import { usePostHog } from 'posthog-js/react';
import React, { useRef, useState } from 'react';
import { toast } from 'sonner';

import { useApiClient } from '@eluve/api-client-provider';
import {
  Button,
  P,
  Textarea,
  ToggleSidebar,
  ToggleSidebarTrigger,
} from '@eluve/components';
import {
  useAppointmentId,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import { useNamedLogger } from '@eluve/logger';
import { FeatureFlagKeysLookup } from '@eluve/posthog';
import {
  useCaptureEventWithSharedContext,
  useFeatureFlagPayload,
} from '@eluve/posthog-react';
import { useTenantIdFromParams } from '@eluve/session-helpers';

export const CustomInstructions = () => {
  const flag = FeatureFlagKeysLookup.NOTE_REGENERATION_WITH_CUSTOM_INSTRUCTIONS;
  const appointmentId = useAppointmentId();
  const logger = useNamedLogger('CustomInstructions');
  const [instructions, setInstructions] = useState<string>('');
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const apiClient = useApiClient();
  const currentSummary = useSummary();
  const tenantId = useTenantIdFromParams();
  const didUserTypeInstruction = useRef<boolean>(false);
  const postHog = usePostHog();

  const exampleChips = useFeatureFlagPayload(flag);

  const captureEvent = useCaptureEventWithSharedContext({
    tenantId,
    appointmentId,
  });

  const handleButtonClick = (instruction: string) => {
    captureEvent('user_clicked_regenerate_note_example_improvement_chip', {
      instruction,
    });
    setInstructions(
      (existing) => `${existing ? `${existing}\n` : ''}${instruction} `,
    );
    textareaRef.current?.focus();
  };

  const handleRegenerate = async () => {
    logger.info('Regenerating summary with custom instructions', {
      appointmentId,
    });

    captureEvent('user_regenerated_note_with_custom_instructions', {
      instructions,
    });

    try {
      const result =
        await apiClient.llm.resummarizeAppointmentWithUserInstructions({
          body: {
            llmOutputId: currentSummary.llmOutputId!,
            userInstruction: instructions,
          },
          params: {
            tenantId: tenantId!,
            appointmentId,
          },
        });

      if (result.status !== 201) {
        throw new Error(
          'Failed to start summarization job with custom instructions',
        );
      }

      toast.success('Regenerating your summary');
    } catch (e) {
      toast.error('Failed to regenerate summary. Please try again');
    }

    return;
  };

  return (
    <>
      <ToggleSidebar className="lg:w-160 w-fit p-0" blurBackground={false}>
        <div className="flex flex-row justify-end pr-2 pt-2">
          <ToggleSidebarTrigger asChild>
            <Button
              size="icon"
              variant="outline"
              className="items-center rounded-sm border-none bg-none"
            >
              <XIcon />
            </Button>
          </ToggleSidebarTrigger>
        </div>
        <div>
          <div className="flex flex-col gap-6 pb-6 pl-6 pr-6">
            <P className="text-2xl text-gray-800">Improve Note</P>
            <P className="font-medium text-gray-500">
              Customize your notes by providing specific guidelines. Try to be
              as clear and concise as possible for best results.
            </P>
          </div>
          <hr className="" />
          <div className="flex flex-col p-6">
            <Textarea
              ref={textareaRef} // Attach the ref to the Textarea
              placeholder="Type your instructions here"
              className="min-h-[120px]"
              value={instructions}
              onChange={(e) => {
                if (!didUserTypeInstruction.current) {
                  captureEvent('user_typed_improve_note_text');
                  didUserTypeInstruction.current = true;
                }
                setInstructions(e.target.value);
              }}
            />
            <div className="flex flex-col gap-3 pb-8 pt-8">
              <P className="text-gray-500">Examples</P>
              {exampleChips.map((instruction, index) => (
                <div key={index}>
                  <Button
                    variant="outlinesecondary"
                    size="xs"
                    onClick={() => handleButtonClick(instruction)}
                  >
                    {instruction}
                  </Button>
                </div>
              ))}
            </div>
            <div className="pb-6">
              <P>These changes will only apply to this note</P>
            </div>

            <div className="flex justify-end">
              <ToggleSidebarTrigger asChild>
                <Button
                  variant="gray"
                  disabled={!instructions}
                  onClick={handleRegenerate}
                >
                  Regenerate Note
                </Button>
              </ToggleSidebarTrigger>
            </div>
          </div>
        </div>
      </ToggleSidebar>
      <ToggleSidebarTrigger>
        <Button
          variant="outlinesecondary"
          size={'xs'}
          onClick={() => {
            captureEvent('user_opened_improve_note_dialog');
            postHog.capture('$feature_interaction', {
              feature_flag: flag,
              $set: { [`$feature_interaction/${flag}`]: true },
            });
          }}
        >
          Improve Note
        </Button>
      </ToggleSidebarTrigger>
    </>
  );
};
