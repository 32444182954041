import { Slot } from '@radix-ui/react-slot';
import React from 'react';
import { HTMLProps } from 'react';
import { useMedia } from 'react-use';
import { tv } from 'tailwind-variants';

import { createSidebarControl } from './createSidebarControl';
import { Sheet, SheetOverlay, SheetPortal } from './sheet';

export const detailsSidebarVariants = tv({
  base: 'bg-background h-full w-full self-stretch overflow-auto border-l p-4',
  variants: {
    variant: {
      fixed: 'col-start-2 row-span-2 row-start-2 min-w-72',
      overlay: [
        'fixed inset-y-0 right-0 z-50 w-max min-w-72 shadow-lg',
        'transition ease-in-out',
        'data-[state=open]:animate-in data-[state=closed]:animate-out',
        'data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right',
        'data-[state=closed]:duration-300 data-[state=open]:duration-500',
      ],
    },
  },
  defaultVariants: {
    variant: 'fixed',
  },
});

interface LayoutProps extends HTMLProps<HTMLDivElement> {
  asChild?: boolean;
}

const useDetailsSidebarVariant = (breakPointWidth: number) =>
  useMedia(`(min-width: ${breakPointWidth}px)`) ? 'fixed' : 'overlay';

const { useSidebar, useSidebarTrigger } = createSidebarControl();

interface DetailsSidebarProps extends LayoutProps {
  /** The width at which the sidebar should switch to overlay
   * @default 768px
   */
  breakpointWidth?: number;
}

const DetailsSidebar = React.forwardRef<HTMLDivElement, DetailsSidebarProps>(
  ({ className, asChild = false, breakpointWidth = 768, ...props }, ref) => {
    const variant = useDetailsSidebarVariant(breakpointWidth);
    const Comp = asChild ? Slot : 'aside';
    const { isOpen, setSidebarOpen } = useSidebar();

    const sidebarContent = (
      <Comp
        className={detailsSidebarVariants({ className, variant })}
        ref={ref}
        data-state={isOpen ? 'open' : 'closed'}
        {...props}
      />
    );

    if (variant === 'fixed') {
      return sidebarContent;
    }

    return (
      <Sheet open={isOpen} onOpenChange={setSidebarOpen}>
        <SheetPortal className="p-0">
          <SheetOverlay onClick={() => setSidebarOpen(false)} />
          {sidebarContent}
        </SheetPortal>
      </Sheet>
    );
  },
);

interface DetailsSidebarTriggerProps extends HTMLProps<HTMLButtonElement> {
  asChild?: boolean;
  /** The width at which the sidebar should switch to overlay
   * @default 768px
   */
  breakpointWidth?: number;
}

const DetailsSidebarTrigger = React.forwardRef<
  HTMLButtonElement,
  DetailsSidebarTriggerProps
>(({ asChild = false, children, breakpointWidth = 768, ...props }, ref) => {
  const variant = useDetailsSidebarVariant(breakpointWidth);
  const { isVisible, setSidebarOpen } = useSidebarTrigger();

  if (variant === 'fixed' || !isVisible) {
    return null;
  }

  const Comp = asChild ? Slot : 'button';

  return (
    <Comp
      {...props}
      ref={ref}
      type="button"
      onClick={() => {
        setSidebarOpen((isOpen) => !isOpen);
      }}
    >
      {children}
    </Comp>
  );
});

export { DetailsSidebar, DetailsSidebarTrigger };
