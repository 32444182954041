import React, { Suspense } from 'react';

import {
  PageTitle,
  ReskinContent,
  ReskinHeader,
  ReskinMain,
  TableSkeleton,
} from '@eluve/components';
import { useCaptureEventOnMount } from '@eluve/posthog-react';

import { PatientsList } from './PatientsList';

export const PatientsListPage: React.FC = () => {
  useCaptureEventOnMount('page_view_patients_list');
  return (
    <ReskinMain>
      <ReskinHeader>
        <PageTitle>Patients</PageTitle>
      </ReskinHeader>
      <ReskinContent variant="fullWidth">
        <Suspense fallback={<TableSkeleton numRows={20} />}>
          <PatientsList />
        </Suspense>
      </ReskinContent>
    </ReskinMain>
  );
};
