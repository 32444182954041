import { PlusIcon } from 'lucide-react';

import { Button } from '@eluve/components';

import { useTranscriptionMachineSelector } from '../appointments/machines/transcription/TranscriptionMachineProvider';
import { useStartAnonymousSession } from '../hooks/useStartAnonymousSession';

export const NewAppointmentButton: React.FC = () => {
  const startAnonymousSession = useStartAnonymousSession();

  const canStartAppointment = useTranscriptionMachineSelector(
    (s) => s.matches('Idle') || s.matches({ InSession: 'Paused' }),
  );

  if (!canStartAppointment) {
    return null;
  }

  return (
    <Button
      variant="gray"
      className="h-auto min-h-10 whitespace-nowrap"
      onClick={startAnonymousSession}
    >
      <PlusIcon className="xs:hidden -ml-1 mr-2 block size-5 " />
      <span className="sr-only">Start a recording session</span>
      New <span className="xs:block xs:ml-1 hidden">Recording</span>
    </Button>
  );
};
