import chunk from 'lodash/chunk';
import { z } from 'zod';

import { AppointmentSummaryKeys } from '@eluve/utils';

import { ERRORS } from '../../errors';
import { AppointmentModel } from '../../models/appointment';
import { PatientType } from '../../models/patient';
import { CanSyncToEhrArgs, Vendor } from '../vendor';

import { JaneVendorContract } from './contract';
import { convertJaneDataToAppointmentModel } from './jane.appointment';
import { logo } from './logo';
import { appointmentSchema, chartEntrySchema, patientSchema } from './types';

const appointmentData = z.object({
  staff_member_ids: z.array(z.number().nullish()),
  appointments: z.array(z.unknown()),
  shifts: z.array(z.unknown()),
  openings: z.array(z.unknown()),
});

export class JaneVendor implements Vendor {
  getChart = (data: unknown, additionalFields?: Record<string, unknown>) => {
    const { domain } = additionalFields ?? {};
    const chart = chartEntrySchema.safeParse(data);
    if (!chart.success) {
      throw new Error(`Invalid Jane chart data: ${chart.error}`);
    }

    return {
      externalChartId: `${chart.data.id}`,
      externalPatientId: `${chart.data.patient_id}`,
      externalAppointmentId: chart.data.appointment_id
        ? `${chart.data.appointment_id}`
        : null,
      rawData: data as Record<string, unknown>,
      signedAt: chart.data.signed_at,
      chartUrl: domain
        ? `https://${domain}/admin#patients/${chart.data.patient_id}/charts/${chart.data.id}`
        : undefined,
    };
  };

  getAppointments = (data: unknown): AppointmentModel[] => {
    const rawAppointments = appointmentData.parse(data);
    const appointments: AppointmentModel[] = [];
    for (const appointment of rawAppointments.appointments) {
      const appointmentParseResult = appointmentSchema.safeParse(appointment);
      if (!appointmentParseResult.success) {
        throw new Error(
          `Invalid appointment data from 3rd party EHR: ${appointmentParseResult.error}`,
        );
      }
      const appointmentModel = convertJaneDataToAppointmentModel(
        appointmentParseResult.data,
      );
      appointments.push(appointmentModel);
    }

    return appointments;
  };

  getPatient = (data: unknown): PatientType => {
    const janeData = patientSchema.parse(data);

    return {
      firstName: janeData.first_name ?? '',
      lastName: janeData.last_name ?? '',
      externalPatientId: `${janeData.id}`,
      dateOfBirth: janeData.dob ?? null,
      email: janeData.email ?? null,
      homePhone: janeData.home_phone ?? null,
      cellPhone: janeData.mobile_phone ?? null,
      workPhone: janeData.work_phone ?? null,
      rawData: data as Record<string, unknown>,
    };
  };

  getPatientEhrUrl = ({
    domain,
    externalPatientId,
  }: {
    domain: string;
    externalPatientId?: string;
  }) => {
    return `https://${domain}/admin#patients/${externalPatientId}/charts`;
  };

  getLogo = () => logo;

  getHomeUrl = (domain: string) => {
    return `https://${domain}/admin`;
  };

  getChartIdFromChartUrl = (chartUrl: string) => {
    const regex = /#patients\/\d+\/charts\/(\d+)/;
    const match = chartUrl.match(regex);
    if (match && match.length > 1 && match[1]) {
      return match[1];
    }
    return null;
  };

  getStaffMemberId = async ({
    domain,
    request,
  }: {
    domain: string;
    request: typeof fetch;
  }) => {
    try {
      const response = await request(`https://${domain}/admin/prefs`);
      if (!response.ok) {
        return { ok: false, error: ERRORS.NOT_LOGGED_IN };
      }
      const prefs = (await response.json()) as { staff_badges: { id: number } };
      const id = prefs?.staff_badges?.id;
      return {
        ok: true,
        data: id,
      };
    } catch (_error) {
      return { ok: false, error: ERRORS.NOT_LOGGED_IN };
    }
  };

  fetchAppointments = async (args: {
    startDate: string;
    endDate: string;
    includeUnscheduled?: boolean;
    domain: string;
    request: typeof fetch;
    staffMemberIds?: number[];
  }) => {
    const {
      startDate,
      endDate,
      includeUnscheduled = true,
      domain,
      request,
      staffMemberIds = [],
    } = args;
    const baseUrl = `https://${domain}/admin/api/v2/calendar`;
    const url = new URL(baseUrl);

    if (!staffMemberIds.length) {
      const staffMemberIdResponse = await this.getStaffMemberId({
        request: fetch,
        domain,
      });

      if (!staffMemberIdResponse.ok || !staffMemberIdResponse.data) {
        return { ok: false, error: staffMemberIdResponse.error };
      }
      staffMemberIds.push(staffMemberIdResponse.data);
    }

    url.searchParams.append('start_date', startDate);
    url.searchParams.append('end_date', endDate);
    url.searchParams.append('include_unscheduled', `${includeUnscheduled}`);
    url.searchParams.append('staff_member_ids[]', staffMemberIds.join(','));
    const response = await request(url.toString());
    if (response.status === 401) {
      return { ok: false, error: ERRORS.NOT_LOGGED_IN };
    }
    if (!response.ok) {
      const body = await response.json();
      return {
        ok: false,
        error: `Could not fetch calendar events: ${JSON.stringify({
          body,
          status: response.status,
        })}`,
      };
    }
    const appointmentData: JaneVendorContract['JANE']['types']['AppointmentsResponse'] =
      await response.json();
    const timezone = await this.getTimezone({
      appointmentIds: appointmentData?.appointments?.map(
        (appointment) => appointment.id,
      ),
      request,
      domain,
    });

    const data = {
      response: appointmentData,
      timezone,
    };

    return { ok: true, data };
  };

  fetchPatientsByIds = async ({
    ids,
    request,
    domain,
  }: {
    ids: number[];
    domain: string;
    request: typeof fetch;
  }) => {
    const patients: any[] = [];
    const batchSize = 4;

    const idChunks = chunk(ids, batchSize);

    let error = '';
    for (const chunk of idChunks) {
      try {
        const responses = await Promise.all(
          chunk.map(async (id) => {
            const response = await request(
              `https://${domain}/admin/api/v2/patients/${id}`,
            );

            if (!response.ok) {
              error = `Could not fetch patient with id ${id}. Status: ${response.status}`;
              return null;
            }

            return response.json();
          }),
        );

        patients.push(...responses.filter(Boolean));
      } catch (error) {
        return { ok: false, error: (error as Error)?.message };
      }
    }

    return { ok: true, data: patients, error };
  };

  private getTimezone = async ({
    appointmentIds,
    request,
    domain,
  }: {
    appointmentIds: number[];
    request: typeof fetch;
    domain: string;
  }): Promise<string | null> => {
    if (appointmentIds?.length) {
      for (const appointmentId of appointmentIds) {
        const response = await request(
          `https://${domain}/admin/api/v3/appointments/${appointmentId}`,
        );
        const appointment: JaneVendorContract['JANE']['types']['Appointment'] =
          await response.json();
        const { start_at } = appointment;
        if (start_at) {
          const timezoneMatch = start_at.match(/([+-][0-9]{2}:[0-9]{2})$/);
          if (timezoneMatch) {
            return timezoneMatch[0];
          }
        }
      }
    }
    return null;
  };

  canSyncNoteToEhr(data: CanSyncToEhrArgs): boolean {
    return Boolean(data.externalPatientId);
  }

  canSignNoteInEhr(): boolean {
    return true;
  }

  canSyncWithManualChartUrl(): boolean {
    return true;
  }

  convertChartToParts = (data: unknown) => {
    const chart = chartEntrySchema.safeParse(data);
    if (!chart.success) {
      return [];
    }
    return chart.data?.chart_parts ?? [];
  };

  convertChartToSummary = (data: unknown) => {
    const summary: { [key in AppointmentSummaryKeys]?: string } = {};
    if (!data) {
      return summary;
    }

    const chart = chartEntrySchema.safeParse(data);
    if (!chart.success) {
      return summary;
    }
    const chartData = chart.data;
    const chartParts = chartData?.chart_parts ?? [];

    for (const chartPart of chartParts) {
      const { label, text } = chartPart;
      if (!text) continue;
      if (label === 'Subjective') {
        summary.SUBJECTIVE = text;
      } else if (label === 'Objective') {
        summary.OBJECTIVE = text;
      } else if (label === 'Assessment') {
        summary.ASSESSMENT = text;
      } else if (label === 'Plan') {
        summary.PLAN = text;
      }
    }
    return summary;
  };
}
