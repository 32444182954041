import { useSuspenseQuery } from '@apollo/client';
import noop from 'lodash/noop';
import React from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import { useParams } from 'react-router-dom';

import { TranscriptText } from '@eluve/blocks';
import {
  Box,
  Divider,
  H2,
  H4,
  P,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  useSearchParamValue,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { SummarySectionMarkdown } from '@eluve/smart-blocks';
import { removeSpecialCharacters } from '@eluve/utils';
import {
  AppointmentSummaryKeys,
  summaryKeyToSectionTitleMap,
  toSentences,
} from '@eluve/utils';
import { getVendorProvider } from '@eluve/vendor';

const appointmentArtifactsFragment = graphql(`
  fragment appointmentArtifacts on Appointments @_unmask {
    __typename
    id
    transcription {
      __typename
      id
      transcript
    }
    doctor_interaction {
      __typename
      appointmentId
      noteSignedAt
      additionalNotes
      externalChartUrl
      externalEhrSyncStatus
    }
    external_charts {
      __typename
      id
      rawData
      external_ehr {
        __typename
        id
        vendor
        domain
      }
    }
    humanOutputs(
      where: { output: { outputType: { _eq: SOAP_NOTE } } }
      limit: 1
      orderBy: { output: { createdAt: DESC } }
    ) {
      __typename
      humanOutputId
      output {
        __typename
        id
        content
        userId
        llm_output {
          __typename
          id
          content
          modelPromptTemplate {
            __typename
            id
            template {
              __typename
              id
              name
            }
          }
        }
      }
    }
  }
`);

const appointmentArtifactsQuery = graphql(
  `
    query appointmentArtifacts($tenantId: uuid!, $appointmentId: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        ...appointmentArtifacts
      }
    }
  `,
  [appointmentArtifactsFragment],
);

type Tab = 'transcript' | 'summary' | 'draft' | 'summary-diff' | 'ehr-diff';

export const AdminAppointmentArtifacts: React.FC = () => {
  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };
  const [tab, setTab] = useSearchParamValue<Tab>('tab');

  const {
    data: { appointmentsByPk: appointment },
  } = useSuspenseQuery(appointmentArtifactsQuery, {
    variables: { tenantId, appointmentId },
  });

  const externalChart = appointment?.external_charts?.[0];
  const externalEhrVendor = externalChart?.external_ehr?.vendor;

  let ehrSignedNote: { [key in AppointmentSummaryKeys]?: string } = {};
  let chartParts: Record<string, unknown>[] = [];
  if (externalEhrVendor) {
    const vendorProvider = getVendorProvider(externalEhrVendor);
    if (vendorProvider.convertChartToSummary) {
      ehrSignedNote = vendorProvider.convertChartToSummary(
        externalChart?.rawData ?? {},
      );
    }
    if (vendorProvider.convertChartToParts) {
      chartParts = vendorProvider.convertChartToParts(externalChart?.rawData);
    }
  }

  const humanSoapNote = appointment?.humanOutputs?.[0];
  const humanSoapNoteContent = humanSoapNote?.output?.content;
  const llmSoapNoteContent = humanSoapNote?.output?.llm_output?.content;
  const { additionalNotes } = appointment?.doctor_interaction ?? {};

  const transcriptText = appointment?.transcription?.transcript ?? '';
  const transcriptWordCount = transcriptText.split(/\s+/).length;

  return (
    <Tabs value={tab ?? 'summary'}>
      <TabsList className="w-full bg-transparent">
        <TabsTrigger
          value="summary"
          onClick={() => setTab('summary')}
          className="bg-gray-4 m-2 px-10"
        >
          LLM Summary
        </TabsTrigger>
        <TabsTrigger
          value="draft"
          onClick={() => setTab('draft')}
          className="bg-gray-4 m-2 px-10"
        >
          Doctor Draft
        </TabsTrigger>
        <TabsTrigger
          value="summary-diff"
          onClick={() => setTab('summary-diff')}
          className="bg-gray-4 m-2 px-10"
        >
          Summary Diff
        </TabsTrigger>
        <TabsTrigger
          value="transcript"
          onClick={() => setTab('transcript')}
          className="bg-gray-4 m-2 px-10"
        >
          Transcript
        </TabsTrigger>
        {externalChart && (
          <TabsTrigger
            value="ehr-diff"
            onClick={() => setTab('ehr-diff')}
            className="bg-gray-4 m-2 px-10"
          >
            EHR Diff
          </TabsTrigger>
        )}
      </TabsList>

      <TabsContent value="draft">
        <div className="flex flex-col gap-2">
          {Object.entries(summaryKeyToSectionTitleMap)
            .filter(([summaryKey]) => humanSoapNoteContent?.[summaryKey])
            .map(([key, sectionTitle]) => {
              const summaryValue = humanSoapNoteContent?.[key];
              const summaryKey = key as AppointmentSummaryKeys;
              return (
                <SummarySectionMarkdown
                  appointmentId={appointmentId}
                  key={summaryKey}
                  summaryKey={summaryKey}
                  sectionTitle={sectionTitle}
                  content={summaryValue}
                  disabled={true}
                  handleContentChange={noop}
                />
              );
            })}

          {appointment?.doctor_interaction?.additionalNotes && (
            <SummarySectionMarkdown
              appointmentId={appointmentId}
              key="AdditionalNotes"
              summaryKey={`AdditionalNotes` as any}
              sectionTitle="Additional Notes"
              content={appointment?.doctor_interaction?.additionalNotes}
              disabled={true}
              handleContentChange={noop}
            />
          )}
        </div>
      </TabsContent>
      <TabsContent value="summary">
        <div className="flex flex-col gap-2">
          {Object.entries(summaryKeyToSectionTitleMap)
            .filter(([summaryKey]) => llmSoapNoteContent?.[summaryKey])
            .map(([key, sectionTitle]) => {
              const summaryValue = llmSoapNoteContent?.[key];
              const summaryKey = key as AppointmentSummaryKeys;
              return (
                <SummarySectionMarkdown
                  appointmentId={appointmentId}
                  key={summaryKey}
                  summaryKey={summaryKey}
                  sectionTitle={sectionTitle}
                  content={summaryValue}
                  disabled={true}
                  handleContentChange={noop}
                />
              );
            })}

          {additionalNotes && (
            <SummarySectionMarkdown
              appointmentId={appointmentId}
              key="AdditionalNotes"
              // TODO(jesse)[ELU-949]: this should get cleaned up and use a different component
              summaryKey={`AdditionalNotes` as any}
              sectionTitle="Additional Notes"
              content={additionalNotes}
              disabled={true}
              handleContentChange={noop}
            />
          )}
        </div>
      </TabsContent>
      <TabsContent value="ehr-diff">
        <div className="flex flex-col gap-8">
          {Object.entries(summaryKeyToSectionTitleMap).map(
            ([key, sectionTitle]) => {
              const summaryKey = key as AppointmentSummaryKeys;
              return (
                <div className="flex flex-col gap-2" key={summaryKey}>
                  <H2>{sectionTitle}</H2>
                  <div className="text-sm">
                    <ReactDiffViewer
                      oldValue={removeSpecialCharacters(
                        humanSoapNoteContent?.[summaryKey],
                      )}
                      newValue={removeSpecialCharacters(
                        ehrSignedNote?.[summaryKey],
                      )}
                      splitView={true}
                      showDiffOnly={true}
                    />
                  </div>
                </div>
              );
            },
          )}

          {additionalNotes && (
            <SummarySectionMarkdown
              appointmentId={appointmentId}
              key="AdditionalNotes"
              summaryKey={`AdditionalNotes` as any}
              sectionTitle="Additional Notes"
              content={additionalNotes}
              disabled={true}
              handleContentChange={noop}
            />
          )}
          {chartParts?.length && (
            <div>
              <H2>View Raw EHR Chart</H2>
              {chartParts.map((part, index) => {
                return (
                  <div key={`chart-part-${index}`} className="mb-4 mt-4">
                    <Table className="min-w-full divide-y divide-gray-200">
                      <TableHeader className="bg-gray-50">
                        <H4 className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                          Chart Entry
                        </H4>
                      </TableHeader>
                      <TableBody className="divide-y divide-gray-200 bg-white">
                        {Object.entries(part).map(([key, value], rowIndex) => (
                          <TableRow
                            key={`row-${index}-${rowIndex}`}
                            className={
                              rowIndex % 2 === 0 ? 'bg-gray-50' : 'bg-white'
                            }
                          >
                            <TableCell className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                              <P>{key}</P>
                            </TableCell>
                            <TableCell className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                              <P>{JSON.stringify(value)}</P>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </TabsContent>

      <TabsContent value="summary-diff">
        <div className="flex flex-col gap-8">
          {Object.entries(summaryKeyToSectionTitleMap).map(
            ([key, sectionTitle]) => {
              const summaryKey = key as AppointmentSummaryKeys;
              return (
                <div className="flex flex-col gap-2" key={summaryKey}>
                  <H2>{sectionTitle}</H2>
                  <div className="text-sm">
                    <ReactDiffViewer
                      oldValue={removeSpecialCharacters(
                        llmSoapNoteContent?.[summaryKey],
                      )}
                      newValue={removeSpecialCharacters(
                        humanSoapNoteContent?.[summaryKey],
                      )}
                      splitView={true}
                      showDiffOnly={true}
                    />
                  </div>
                </div>
              );
            },
          )}

          {additionalNotes && (
            <SummarySectionMarkdown
              appointmentId={appointmentId}
              key="AdditionalNotes"
              // TODO(jesse): this should get cleaned up and use a different component [ELU-949]
              summaryKey={`AdditionalNotes` as any}
              sectionTitle="Additional Notes"
              content={additionalNotes}
              disabled={true}
              handleContentChange={noop}
            />
          )}
        </div>
      </TabsContent>
      <TabsContent value="transcript">
        <div className="m-2 flex flex-col">
          <div className="flex flex-col gap-2">
            Word count: {transcriptWordCount}
          </div>
        </div>
        <Box className="mt-6 rounded-lg border p-4">
          <h2 className="text-semibold mb-4 text-lg">Transcript</h2>
          <Divider />
          <TranscriptText className="h-96 overflow-y-auto">
            {toSentences(appointment?.transcription?.transcript ?? '')}
          </TranscriptText>
        </Box>
      </TabsContent>
    </Tabs>
  );
};
