import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { toast } from 'sonner';

import { Button } from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';

import { UserSettings } from './UserSettings';

const bytesToMB = (bytes: number) => bytes / 1024 / 1024;

const UsedJSHeapSizeThreshold = 1000;

export const AppLayout: React.FC = () => {
  const logger = useNamedLogger('App');
  const [toastOpen, setToastOpen] = useState<boolean>(false);

  useEffect(
    function reportMemoryUsage() {
      const interval = setInterval(
        () => {
          if (window.performance && window.performance.memory) {
            const { jsHeapSizeLimit, totalJSHeapSize, usedJSHeapSize } =
              window.performance.memory;

            logger.info('Memory usage', {
              jsHeapSizeLimit: bytesToMB(jsHeapSizeLimit),
              totalJSHeapSize: bytesToMB(totalJSHeapSize),
              usedJSHeapSize: bytesToMB(usedJSHeapSize),
            });

            if (bytesToMB(usedJSHeapSize) > UsedJSHeapSizeThreshold) {
              logger.info(
                'Prompting user to reload the page to release memory',
              );
              setToastOpen(true);
            }
          }
        },
        5 * 60 * 1000,
      );

      return () => {
        clearInterval(interval);
      };
    },
    [logger, setToastOpen],
  );

  useEffect(
    function displayToast() {
      if (!toastOpen) {
        return;
      }
      toast(
        <div className="flex w-full flex-col items-center justify-center sm:flex-row">
          <div className="mr-3">
            Things might run smoother with a quick page reload. Care to try?
          </div>
          <div>
            <Button
              variant="toastprimary"
              size="xs"
              onClick={() => window.location.reload()}
            >
              Reload now
            </Button>
            <Button
              variant="toastsecondary"
              size="xs"
              className="mt-1"
              onClick={() => toast.dismiss()}
            >
              Dismiss
            </Button>
          </div>
        </div>,
        {
          position: 'bottom-right',
          duration: Infinity,
          onDismiss: () => {
            setToastOpen(false);
          },
        },
      );
    },
    [toastOpen, setToastOpen],
  );

  return (
    <UserSettings>
      <Outlet />
    </UserSettings>
  );
};
