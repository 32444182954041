import { Info } from 'lucide-react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { P, match } from 'ts-pattern';

import { useCompleteFragment } from '@eluve/apollo-client';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  H3,
  P as Paragraph,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

export const promptTemplateTextFragment = graphql(`
  fragment PromptTemplateText on PromptTemplates {
    __typename
    id
    template
    className
  }
`);

export const PromptTemplateDetailsPromptTextPage: React.FC = () => {
  const { promptTemplateId } = useParams() as { promptTemplateId: string };
  return (
    <PromptTemplateDetailsPromptText promptTemplateId={promptTemplateId} />
  );
};

export interface PromptTemplateDetailsPromptTextProps {
  promptTemplateId: string;
}

export const PromptTemplateDetailsPromptText: React.FC<
  PromptTemplateDetailsPromptTextProps
> = ({ promptTemplateId }) => {
  const { template, className } = useCompleteFragment({
    fragment: promptTemplateTextFragment,
    key: { id: promptTemplateId },
  });

  return match(template)
    .with(P.nullish, () => (
      <Alert>
        <Info className="size-4" />
        <AlertTitle>Legacy Prompt</AlertTitle>
        <AlertDescription>
          The contents of this prompt template are not tracked in the Database.
        </AlertDescription>
        <AlertDescription>
          Review the source code for this template by referring to the class{' '}
          <span className="font-bold">{className}</span>
        </AlertDescription>
      </Alert>
    ))
    .otherwise((t) => (
      <>
        {(t as []).map((t: Record<string, string>, index) => (
          <div key={index}>
            <div onClick={() => navigator.clipboard.writeText(t.content ?? '')}>
              <H3 className="pb-1 font-bold hover:cursor-pointer">{t.role}</H3>
            </div>
            <div className="bg-gray-3 mb-8 flex max-h-[700px] flex-col gap-4 overflow-y-scroll rounded-md p-5">
              <Paragraph>
                <pre className="text-wrap">{t.content}</pre>
              </Paragraph>
            </div>
          </div>
        ))}
      </>
    ));
};
