import clsx from 'clsx';
import { ExternalLink, Trash } from 'lucide-react';
import React from 'react';

import { TooltipLabel } from '@eluve/blocks';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Badge,
  Button,
  H3,
  P,
  cn,
} from '@eluve/components';

import { BillingCodeRecommendationProps } from './BillingCodeRecommendationProps';

export const BillingCodeRecommendation = React.forwardRef<
  HTMLDivElement,
  BillingCodeRecommendationProps & { className?: string }
>((props, ref) => {
  const { code, type, description, justification, onDismiss, className } =
    props;

  return (
    <div className={cn('border p-4 shadow-md', className)} key={code} ref={ref}>
      <div className="items-top flex justify-between gap-6">
        <div className="flex items-center gap-2">
          <Badge
            className={clsx({
              'bg-cyan-500': type === 'CPT',
              'bg-primary': type === 'ICD-10',
              'bg-gray-10': type !== 'CPT' && type !== 'ICD-10',
            })}
          >
            {type}
          </Badge>
          <H3 className="text-brand-12">{code}</H3>
          {type === 'ICD-10' && (
            <a
              href={`https://icd.who.int/browse10/2019/en#${code}`}
              target="_blank"
              className="underline"
              rel="noreferrer"
            >
              <ExternalLink className="size-4" />
            </a>
          )}
        </div>
        <div className="flex items-center gap-1">
          <TooltipLabel label="Dismiss this recommendation">
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button variant="outline" size="smallIcon">
                  <Trash className="size-4" />
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Dismiss Recommendation</AlertDialogTitle>
                  <AlertDialogDescription>
                    This will remove the Billing Code recommendation from this
                    appointment
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={onDismiss}>
                    Dismiss
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </TooltipLabel>
        </div>
      </div>
      <P className="text-xs font-light italic">{description}</P>
      <P className="mt-2 font-normal">
        <span className="font-extrabold">Justification: </span>
        {justification}
      </P>
    </div>
  );
});
