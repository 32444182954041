import React from 'react';

import { PageTitle } from '@eluve/components';
import { CreatePromptTemplateForm } from '@eluve/frontend-feature-prompt-templates';

export const CreatePromptPage: React.FC = () => {
  return (
    <div className="container">
      <div className="flex items-center gap-4">
        <PageTitle className="flex-1">Create a Prompt</PageTitle>
      </div>
      <CreatePromptTemplateForm />
    </div>
  );
};
