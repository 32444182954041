import React from 'react';

import { H2 } from '@eluve/components';

export interface OutputTitleProps {
  title: string;
}

export const OutputTitle: React.FC<OutputTitleProps> = ({ title }) => {
  return <H2 className="text-gray-11 text-xl underline">{title}</H2>;
};
