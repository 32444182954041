import { cn } from './cn';
import { FCC } from './types';
import { H3 } from './typography';

export const PageSubtitle: FCC<{ className?: string }> = ({
  children,
  className,
}) => {
  return <H3 className={cn('text-gray-11  pb-2', className)}>{children}</H3>;
};
