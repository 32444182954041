import { cn } from './cn';
import { FCC } from './types';

export const H1: FCC<{ className?: string }> = ({ children, className }) => {
  return (
    <h1 className={cn('scroll-m-20 text-2xl font-bold md:text-3xl', className)}>
      {children}
    </h1>
  );
};

export const H2: FCC<{ className?: string }> = ({ children, className }) => {
  return (
    <h2
      className={cn(
        'scroll-m-20 text-lg font-bold leading-7 tracking-tight md:text-xl',
        className,
      )}
    >
      {children}
    </h2>
  );
};

export const H3: FCC<{ className?: string }> = ({ children, className }) => {
  return (
    <h3
      className={cn(
        'scroll-m-20 text-base font-medium leading-6 tracking-tight md:text-lg',
        className,
      )}
    >
      {children}
    </h3>
  );
};

export const H4: FCC<{ className?: string }> = ({ children, className }) => {
  return (
    <h4
      className={cn(
        'scroll-m-20 text-sm font-semibold tracking-wide md:text-base',
        className,
      )}
    >
      {children}
    </h4>
  );
};

export const H5: FCC<{ className?: string }> = ({ children, className }) => {
  return (
    <h5
      className={cn(
        'scroll-m-20 text-xs font-semibold leading-5 tracking-wide sm:text-sm',
        className,
      )}
    >
      {children}
    </h5>
  );
};

export const P: FCC<{ className?: string }> = ({ children, className }) => {
  return (
    <p
      className={cn('scroll-m-20 text-sm font-medium tracking-wide', className)}
    >
      {children}
    </p>
  );
};
