import React, { Suspense } from 'react';

import {
  PageTitle,
  ReskinContent,
  ReskinHeader,
  ReskinMain,
  TableSkeleton,
} from '@eluve/components';
import { useCaptureEventOnMount } from '@eluve/posthog-react';

import { AppointmentsList } from './AppointmentsList';

export const AppointmentsListPage: React.FC = () => {
  useCaptureEventOnMount('page_view_appointments_list');

  return (
    <ReskinMain>
      <ReskinHeader>
        <PageTitle>Appointments</PageTitle>
      </ReskinHeader>
      <ReskinContent variant="fullWidth">
        <Suspense fallback={<TableSkeleton numRows={20} />}>
          <AppointmentsList />
        </Suspense>
      </ReskinContent>
    </ReskinMain>
  );
};
