import { GripVerticalIcon, Maximize2Icon, XIcon } from 'lucide-react';
import Draggable from 'react-draggable';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { Box, Button } from '@eluve/components';

import { AppointmentTranscriptionInSessionControls } from '../../../components/AppointmentTranscriptionInSessionControls';
import {
  useTranscriptionMachineActor,
  useTranscriptionMachineSelector,
} from '../TranscriptionMachineProvider';

import { AppointmentPatientDetails } from './AppointmentPatientDetails';
import { TranscriptionWaveform } from './TranscriptionWaveform';
import { TranscriptionWidgetTranscript } from './TranscriptionWidgetTranscript';

export const TranscriptionWidget = () => {
  const { tenantId } = useParams<{ tenantId: string }>();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { send } = useTranscriptionMachineActor();

  const sessionAppointmentId = useTranscriptionMachineSelector(
    (state) => state.context.sessionAppointmentId,
  );

  const canDismissSession = useTranscriptionMachineSelector((state) =>
    state.can({ type: 'DISMISS_SESSION' }),
  );

  const currentSessionAppointmentPath = sessionAppointmentId
    ? `/tenants/${tenantId}/appointments/${sessionAppointmentId}`
    : null;

  if (
    !sessionAppointmentId ||
    !currentSessionAppointmentPath ||
    matchPath(currentSessionAppointmentPath, pathname)
  ) {
    return null;
  }

  const openAppointment = () => {
    navigate(currentSessionAppointmentPath);
  };

  return (
    <Draggable bounds="parent" handle=".move-handle">
      <Box
        vStack
        className="bg-background fixed bottom-5 right-5 z-[1000] h-[250px] w-[calc(100%-40px)] max-w-[550px] gap-0 overflow-hidden rounded-lg border shadow-xl"
      >
        <Box
          spaceBetween
          className="bg-mainBackground w-full items-center px-4 py-2"
        >
          <Box hStack>
            <GripVerticalIcon
              size={24}
              className="move-handle hidden cursor-move md:block"
            />
            <AppointmentPatientDetails appointmentId={sessionAppointmentId} />
          </Box>
          <Box hStack>
            <Button onClick={openAppointment} size="icon" variant="outline">
              <Maximize2Icon size={24} />
            </Button>
            {canDismissSession && (
              <Button
                onClick={() => send({ type: 'DISMISS_SESSION' })}
                size="icon"
                variant="outline"
              >
                <XIcon size={24} />
              </Button>
            )}
          </Box>
        </Box>
        <TranscriptionWidgetTranscript />
        <Box
          spaceBetween
          className="bg-mainBackground w-full flex-wrap-reverse items-center gap-1 p-2"
        >
          <TranscriptionWaveform />
          <AppointmentTranscriptionInSessionControls />
        </Box>
      </Box>
    </Draggable>
  );
};
