import { JsonType, PostHogConfig } from 'posthog-js';
import { ZodSchema } from 'zod';

import { MatchingRecord } from '@eluve/utils';

import { noteRegenerationExampleChips } from './regenerate-with-custom-instructions';
import { SchemaWithDefaults } from './utils';

export type FeatureFlagDefinitions = Record<
  string,
  SchemaWithDefaults<ZodSchema<JsonType>>
>;

export const featureFlagsWithPayloads = {
  NOTE_REGENERATION_WITH_CUSTOM_INSTRUCTIONS: noteRegenerationExampleChips,
} as const satisfies FeatureFlagDefinitions;

export type FeatureFlagKeys = keyof typeof featureFlagsWithPayloads;

export const FeatureFlagKeysLookup = {
  NOTE_REGENERATION_WITH_CUSTOM_INSTRUCTIONS:
    'NOTE_REGENERATION_WITH_CUSTOM_INSTRUCTIONS',
} satisfies MatchingRecord<FeatureFlagKeys>;

type PostHogBootstrap = PostHogConfig['bootstrap'];

export const makeBootstrappedFlags = (
  input: Record<string, SchemaWithDefaults<ZodSchema<JsonType>>>,
): Pick<PostHogBootstrap, 'featureFlagPayloads' | 'featureFlags'> => {
  const featureFlags: PostHogBootstrap['featureFlags'] = {};
  const featureFlagPayloads: PostHogBootstrap['featureFlagPayloads'] = {};

  for (const [key, val] of Object.entries(input)) {
    featureFlags[key] = val.defaultEnabled;
    featureFlagPayloads[key] = val.defaultValue;
  }

  return {
    featureFlagPayloads,
    featureFlags,
  };
};

export const bootstrappedFlags = makeBootstrappedFlags(
  featureFlagsWithPayloads,
);
