import { usePostHog } from 'posthog-js/react';

export const useCaptureEventWithSharedContext = (
  context: Record<string, unknown>,
) => {
  const postHog = usePostHog();
  return (eventName: string, properties: Record<string, unknown> = {}) => {
    postHog.capture(eventName, {
      ...context,
      ...properties,
    });
  };
};
