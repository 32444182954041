import { useSuspenseQuery } from '@apollo/client';
import { PlusIcon } from 'lucide-react';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  ColDefBuilder,
  DataTable,
  P,
  PageTitle,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

export const getArtifactsQuery = graphql(`
  query getArtifacts {
    evalSourceArtifact {
      __typename
      id
      name
      description
      content
      createdAt
    }
  }
`);

type ArtifactRow = {
  id: string;
  name: string | null;
  description: string | null;
  content: string;
  createdAt: string;
};

const columns = new ColDefBuilder<ArtifactRow>()
  .linkSortable('name', (row) => `./${row.id}`)
  .defaultSortable('description')
  .defaultSortable('content', {
    cellRenderer: (row) => (
      <P>
        {' '}
        {row.content.length > 300
          ? `${row.content.substring(0, 300)}...`
          : row.content}
      </P>
    ),
  })
  .dateSortable('createdAt', 'Created At')
  .build();

export const ArtifactListPage: React.FC = () => {
  const {
    data: { evalSourceArtifact: artifacts },
  } = useSuspenseQuery(getArtifactsQuery);

  return (
    <div className="max-w-screen-xl">
      <div className="space-between flex w-full items-center">
        <div className="flex-1">
          <PageTitle className="my-2 ml-2 font-light">
            Source Artifacts
          </PageTitle>
        </div>
        <Link to="create">
          <Button>
            <PlusIcon className="mr-2 h-5" />
            Add an artifact
          </Button>
        </Link>
      </div>
      <DataTable
        columns={columns}
        data={artifacts}
        enableGlobalSearch={true}
        initialSortingState={[
          {
            id: 'createdAt',
            desc: true,
          },
        ]}
      />
    </div>
  );
};
